/** Possibly Deprecated */
function NavBar() {
	return (
		<footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between">
		<p className="text-muted text-center text-md-left"></p>
		</footer>
	)
}

export default NavBar
