import React from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import { provideExampleValue } from './higher-order'
import defaultStyle from './defaultStyle'
import defaultMentionStyle from './defaultMentionStyle'
  
function MultipleTriggers({ value, data, onChange, onAdd, onRemove, playerIframe }) {
	return (
		<div className="multiple-triggers">
		<MentionsInput
			value={value}
			onChange={onChange}
			style={defaultStyle}
			placeholder={"Mention people using '@'"}
			a11ySuggestionsListLabel={"Suggested mentions"}
		>
			<Mention
			markup="@[__display__]{__id__}"
			trigger="@"
			data={data}
			renderSuggestion={(
				suggestion,
				search,
				highlightedDisplay,
				index,
				focused
			) => (
				<div className={`user ${focused ? 'focused' : ''}`}>
				{highlightedDisplay}
				</div>
			)}
			onAdd={onAdd}
      onRemove={onRemove}
			style={defaultMentionStyle}
			/>
		</MentionsInput>
		</div>
	)
}

const asExample = provideExampleValue(
    ""
)
  
export default asExample(MultipleTriggers)
