import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
	faStepBackward, 
	faUndo, 
	faPlayCircle, 
	faPauseCircle, 
	faRedo, 
	faStepForward, 
	faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import Viewer from '../Components/Viewer';
import React, { useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';

function ClipControls(props) {
	const customStyles = {
		content : {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)'
		},
		overlay: { zIndex: 1000 }
	}

	const attributes = {
		className: 'custom-root'
	}
	const widthStyle100 = {
		width: '100%'
	}
	const floatRight = {
		float: "right"
	}
	const cursorMouse = {
		cursor: "pointer"
	}
	const muter = {
		display: 'flex',
		alignItems: 'center',
		cursor: "pointer"
	}

	const renderTooltip = (params) => {
		return (
			<Tooltip id="button_tooltip">
				{params.message}
			</Tooltip>
		)
	}

	const clipScrubberInteraction = (e) => {
		Viewer.userInteraction = true;
	}

	const clipScrubberPlay = (e) => {
		if (e.button == 0) {
			Viewer.userInteraction = false;

			let position = document.getElementById('position').value;

			Viewer.playClipFromPosition(position);
		}
	}

	const clipScrubberUpdate = (e) => {
		Viewer.updateClipTimeIndex(e.target.value);
	}

	const volumeUpdate = () => {
		let volume = document.getElementById('volume').value;
		let mute = document.getElementById('mute');

		volume = volume / 100;

		Viewer.isMuted = false;
		Viewer.volume = volume;

		mute.innerHTML = '🔊';
		Viewer.setAllClipVolume(volume, true, false);
	}

	const updateScrubberDuration = (duration) => {
		document.getElementById('position').max = duration;
	}
  
	useEffect(() => {
		let genUserId = localStorage.getItem('genUserId');

		Viewer.setScrubberDuration = updateScrubberDuration;
		Viewer.setRoom(props.room, null, genUserId, true);
	}, []);

	return (
		<div id="controls" style={{ marginTop: '15px', width: '100%' }}>
			<input
				id="position" 
				type="range" 
				min="0" 
				max="100" 
				defaultValue="0" 
				step="1" 
				style={widthStyle100} 
				onMouseDown={clipScrubberInteraction}
				onMouseUp={clipScrubberPlay} 
				onInput={clipScrubberUpdate}
			/>
		<div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Play Clip from Beginning"})}
			>
				<Button 
					id="restart" 
					variant="outline-primary"
					onClick={Viewer.restartClip} 
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faStepBackward} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Jump Back 10 Seconds"})}
			>
				<Button 
					id="jbackButton" 
					variant="outline-primary"
					onClick={Viewer.jumpBackClip}
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faUndo} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Play Clip"})}
			>
				<Button 
					id="play" 
					variant="outline-primary"
					onClick={Viewer.playClip} 
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faPlayCircle} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Pause Clip"})}
			>
				<Button 
					id="pause" 
					variant="outline-primary"
					onClick={Viewer.pauseClip} 
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faPauseCircle} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Jump Forward 10 Seconds"})}
			>
				<Button 
					id="jforwardButton" 
					variant="outline-primary"
					onClick={Viewer.jumpForwardClip}
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faRedo} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Play Clip from End"})}
			>
				<Button 
					id="live" 
					variant="outline-primary"
					onClick={Viewer.endClip} 
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faStepForward} />
				</Button>
			</OverlayTrigger>
			<span 
				className="ml-4" 
				id="mute" 
				style={muter} 
				onClick={Viewer.muteClip}
			>{Viewer.isMuted ? '🔇' : '🔊'}</span>
			<input 
				id="volume"  
				type="range" 
				min="0" 
				max="100" 
				defaultValue={Viewer.isMuted ? 0 : Viewer.volume * 100} 
				step="1" 
				onChange={volumeUpdate}
			/>
		</div>
		<div id="index" style={floatRight}>
		{/* <span id="current"></span>/<span id="files"></span> */}
			<span id="timeIndex"></span>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "/"})}
			>
				<span style={{marginLeft: '2px'}}>
					<FontAwesomeIcon icon={faInfoCircle} />
				</span>
			</OverlayTrigger>
		</div>
		</div>    
	);
}

export default ClipControls;
