import { 
	Container,
} from 'react-bootstrap';
import React, { 
	useState, 
	useEffect,
} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useLocation } from 'react-router-dom';
import Loader from '../Fragments/Loader';
import UserSettings from '../Fragments/User/UserSettings';
import GoogleSSO from '../Fragments/User/GoogleSSO'

function Account() {
	const search = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
		const url = new URL(window.location.href);
		let code = url.searchParams.get('code');
		if( code ) {
			url.searchParams.append('genUserId', localStorage.getItem('genUserId'));
			url.href = window.apiSite + url.pathname + url.search

			fetch(url.href, {
				method: `POST`,
        credentials: `include`,
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				}
			})
		}
		setIsLoading(false);
    }, []);

    return isLoading ? (
      <React.Fragment>
        <Container>
          <Loader/>
        </Container>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Container className="mt-2">
          <Tabs>
            <TabList>
              <Tab>Account Info</Tab>
              <Tab>Single Sign On</Tab>
            </TabList>
            <TabPanel>
              <UserSettings/>
            </TabPanel>
            <TabPanel>
              <GoogleSSO/>
            </TabPanel>
          </Tabs>
        </Container>
      </React.Fragment>
    );
}

export default Account;
