import Logout from './Pages/Logout';
import E404 from './Pages/E404';
import Channels from './Pages/Channels';
import NewChannels from './Pages/NewChannels';
import NewUser from './Pages/NewUser';
import Library from './Pages/Library';
import User from './Pages/User';
import Clip from './Pages/Clip';
import Extension from './Pages/Extension';
import Search from './Pages/Search';
import Reports from './Pages/Reports';
import AdherenceReport from './Pages/adherencereport';
import { ProtectedRoute } from './Components/ProtectedRoute';
import { BrowserRouter, Route, Redirect , Switch } from 'react-router-dom';
import Admin from './Pages/Admin';
import Account from './Pages/Account';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
	return (
		<BrowserRouter>
		<Switch>
			<Route path="/logout" exact component={Logout} />
			<Route exact path = "/">
			<Redirect to="/channels" />
			</Route>

      <ProtectedRoute exact path="/new-user/:id/" component={NewUser} />
			<ProtectedRoute exact path="/user/:id/" component={User} />
			<ProtectedRoute exact path="/user/:id/:datestamp/:position" component={User} />
			<ProtectedRoute exact path="/user/:id/:datestamp/:position/:time/:commentId" component={User} />
			<ProtectedRoute exact path="/channels" component={NewChannels} />
			<ProtectedRoute exact path="/search" component={Search} />
			<ProtectedRoute exact path="/reports" component={Reports} />
			<ProtectedRoute exact path="/adherencereport/:agentid" component={AdherenceReport} />
			<ProtectedRoute exact path="/account" component={Account} />
			<ProtectedRoute exact path="/google/create/redirect" component={Account} />
			<ProtectedRoute exact path="/library/:id" component={Library} />
			<ProtectedRoute exact path="/clip/:id/:UID" component={Clip} />
      <ProtectedRoute exact path="/clip/:id/:UID/:time/:commentId" component={Clip} />
			<ProtectedRoute exact path="/admin" component={Admin} />
			<ProtectedRoute exact path="/ext/:event" component={Extension} />
			<Route path="*" component={E404} />
		</Switch>
		</BrowserRouter>
	);
}

export default App
