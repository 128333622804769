import React, {
    useEffect,
    useState
} from 'react';
import { 
	FontAwesomeIcon 
} from '@fortawesome/react-fontawesome';
import { 
	faTimesCircle,
	faCheckCircle,
	faEyeSlash,
	faEye
} from '@fortawesome/free-solid-svg-icons';
import { sendData, onData, offData } from '../../Hooks/Socket';
import Loader from '../Loader';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';

const styles = {
	colors: {
		primary: {
			color: "#007bff"
		}
	},
	hidden: {
		display: 'none'
	},
	visible: {
		display: 'table-cell'
	},
	font: {
		fontFamily: 'Roboto, Helvetica, sans-serif'
	},
	table: {
		maxHeight: '400px !important'
	},
	card: {
		header: {
			backgroundColor: '#727cf5',
			color: '#fff'
		}
	},
	icons: {
		connected: {
			color: '#10b759',
		},
		disconnected: {
			color: '#ff3366'
		},
		save: {
			color: '#10b759',
			fontSize: '1.2rem',
			cursor: 'pointer',
			marginRight: '10px'
		},
		cancel: {
			color: '#fbbc06',
			fontSize: '1.2rem',
			cursor: 'pointer'
		},
		edit: {
			color: '#007bff',
			fontSize: '1.2rem',
			cursor: 'pointer'
		},
		danger: {
			color: '#ff3366',
			fontSize: '1.2rem',
			cursor: 'pointer'
		}
	},
	alignMiddle: {
		alignItems: "center"
	},
	spinner: {
		height: "5rem",
		width: "5rem"
	}
}

function FiveNine(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [f9Integrated, setF9Integrated] = useState(false);
    const [requiredInfo, setRequiredInfo] = useState([]);
    const [isUpdatingF9, setIsUpdatingF9] = useState(false);
    const [f9Username, setF9Username] = useState(null);
    const [f9Password, setF9Password] = useState(null);
    const [f9Endpoint, setF9Endpoint] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showRequiredInfo, setShowRequiredInfo] = useState(true);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState({
        title: '',
        text: '',
        confirmButton: '',
        confirm: null,
        confirmHandler: null,
        cancelButton: '',
        cancel: null,
        static: false,
        hideEvent: null
    });

    const getF9Integration = () => {
        let data = {
			genUserId: localStorage.getItem('genUserId')
        }
        sendData('getF9Integration', data);
    }

    /**
     * Handles response to get integration
     * request
     * @param {JSON} data response data
     *  @param {Number} data.integrated status of five nine integration
     */
    const onF9Integration = (data) => {
        if (data.integrated) {
			setF9Integrated(true);
        } else {
			setRequiredInfo(data.requiredInfo);
			setF9Integrated(false);
        }
        setIsLoading(false);
    }

    const updateF9Data = () => {
        if (!f9Username || !f9Password || !f9Endpoint) {
			setAlertModalContent({
				title: "Error",
				text: "All fields must be filled out!",
				confirm: false,
				cancel: true,
				cancelButton: "Close"
			});
			handleShowAlertModal();
		} else {
			let data = {
				genUserId: localStorage.getItem('genUserId'),
				username: f9Username,
				password: f9Password,
				endpoint: f9Endpoint
			}
			sendData('updateF9Data', data);
		}
    }

    const onUpdateF9Data = (data) => {
		setAlertModalContent({
			title: "Success",
			text: "You have successfully updated your Five9 Integration information!",
			confirm: false,
			cancel: false,
			hideEvent: modalEventWrapper(props.updIntegrations)
		});
		handleShowAlertModal();
    }

    const setUsername = (username) => {
        setF9Username(username);
    }

    const setPassword = (password) => {
        setF9Password(password);
    }

    const setEndpoint = (url) => {
        setF9Endpoint(url);
    }

    const updateF9Access = () => {
        let data = {
            genUserId: localStorage.getItem('genUserId'),
            f9Username: f9Username,
            f9Password: f9Password
        }
        sendData('updF9Access', data);

        setF9Username(null);
        setF9Password(null);
    }

    const updateF9Endpoint = () => {
        let data = {
            genUserId: localStorage.getItem('genUserId'),
            f9Endpoint: f9Endpoint
        }
        sendData('updF9Endpoint', data);

        setF9Endpoint(null);
    }

    const onUpdateF9Access = (data) => {
        setAlertModalContent({
            title: "Success",
            text: "You have successfully updated your Five9 access!",
            confirm: false,
            cancel: false,
            hideEvent: props.updIntegrations
        });
        handleShowAlertModal();
    }

    /**
     * Handles response to
     * updating five nine url 
     * @param {JSON} data response data
     */
    const onUpdateF9Endpoint = (data) => {
        setAlertModalContent({
            title: "Success",
            text: "You have successfully updated your Five9 endpoint!",
            confirm: false,
            cancel: false,
            hideEvent: props.updIntegrations
        });
        handleShowAlertModal();
    }

    const startRemoveF9Integration = () => {
        setAlertModalContent({
            title: "Remove Five9 Integrations?",
            text: "Are you sure you would like to remove your Five9 integration from Daily Stream? You may integrate again at any time.",
            confirm: true,
            confirmButton: "Confirm",
            confirmHandler: removeF9Integration,
            cancel: true,
            cancelButton: "Cancel"
        });
        handleShowAlertModal();
    }

    const removeF9Integration = () => {
        handleShowAlertModal();
        setF9Username(null);
        setF9Password(null);
        setF9Endpoint(null);

        let data = {
            genUserId: localStorage.getItem('genUserId')
        }
        sendData('removeF9Integration', data);
    }

    /**
     * Handles response to removing
     * five nine integrations
     * @param {JSON} data response data
     */
    const onRemoveF9Integration = (data) => {
        setAlertModalContent({
          title: "Success",
          text: "You have successfully removed your Five9 integration",
          confirm: false,
          cancel: false,
          hideEvent: modalEventWrapper(props.updIntegrations)
        });
        handleShowAlertModal();
    }

    const startImportF9Users = () => {
      setAlertModalContent({
        title: "Import Users?",
        text: "Are you sure you would like to import your users into Daily Stream?",
        confirm: true,
        confirmButton: "Confirm",
        confirmHandler: importF9Users,
        cancel: true,
        static: false,
        cancelButton: "Cancel"
      });
      handleShowAlertModal();
    }

    const importF9Users = () => {
      handleShowAlertModal();

      let data = {
          genUserId: localStorage.getItem('genUserId')
      }
      sendData('importF9Users', data);
    }

    const modalEventWrapper = (cb) => {
		handleShowAlertModal();
		cb();
    }

    const handleShowRequiredInfo = () => setShowRequiredInfo(!showRequiredInfo);

    const handleShowAlertModal = () => setShowAlertModal(!showAlertModal);

    useEffect(() => {
        getF9Integration();

        onData('getF9Integration', onF9Integration);
        onData('updateF9Data', onUpdateF9Data);
        onData('updateF9Access', onUpdateF9Access);
        onData('updateF9Endpoint', onUpdateF9Endpoint);
        onData('removeF9Integration', onRemoveF9Integration);

		return () => {
			offData('getF9Integration');
			offData('updateF9Data');
			offData('updateF9Access');
			offData('updateF9Endpoint');
			offData('removeF9Integration');
		}
    }, []);

    return isLoading ? (
        <Loader/>
    ) : ( 
        <React.Fragment>

        <Modal backdrop={alertModalContent.static ? "static" : true} show={showAlertModal} onHide={alertModalContent.hideEvent ? alertModalContent.hideEvent : handleShowAlertModal}>
            <Modal.Header>
            <Modal.Title>{alertModalContent.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{alertModalContent.text}</Modal.Body>
            { alertModalContent.confirm || alertModalContent.cancel ?
              <Modal.Footer>
              {alertModalContent.confirm ? 
                <Button variant="primary" onClick={() => alertModalContent.confirmHandler()}>
                    {alertModalContent.confirmButton}
                </Button>
              : 
                null
              }
              {alertModalContent.cancel ? 
                <Button variant="danger" onClick={handleShowAlertModal}>
                    {alertModalContent.cancelButton}
                </Button>
              : 
                null
              }
              </Modal.Footer>
            :
              null
            }
        </Modal>

        <Row className="mb-2" xl={1} lg={1}>
          <Col className="mb-2">
            { isUpdatingF9 ?
              <Card>
                <Card.Header>
                  Integration
                </Card.Header>
                <Card.Body>
                <Form>
                    <Form.Label>Five9 Username</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" value={f9Username} placeholder="Username" onChange={(e) => setUsername(e.target.value)} />
                    </InputGroup>
                    <Form.Label>Five9 Password</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control type={showPassword ? "text" : "password"} value={f9Password} placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                        <InputGroup.Prepend>
                          <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                          <FontAwesomeIcon 
                              size="lg"
                              icon={showPassword ? faEye : faEyeSlash}  
                          />
                          </Button>
                        </InputGroup.Prepend>
                    </InputGroup>
                    <Form.Label>Five9 Endpoint</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" placeholder="app-scl.five9.com" onChange={(e) => setEndpoint(e.target.value)}/>
                    </InputGroup>
                    <Button variant="primary" onClick={() => updateF9Data()} className="m-2">Save</Button>
                    {/* <Button variant="primary" onClick={() => updateF9Access()} className="mr-2">
                        Update Username and Password
                    </Button>
                    <Button variant="primary" onClick={() => updateF9Endpoint()} className="mr-2">
                        Update Endpoint
                    </Button> */} 
                    <Button variant="danger" onClick={() => setIsUpdatingF9(false)} className="m-2">
                        Cancel
                    </Button>
                </Form>
                </Card.Body>
                </Card>
               : 
               <React.Fragment> 
                { f9Integrated ? 
                <Card>
                    <Card.Header>
                    Integration
                    <FontAwesomeIcon 
                    size="lg"
                    pull="right"
                    style={styles.icons.connected}
                    icon={faCheckCircle}  
                    />
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                    Update Five9 Integration
                    </Card.Title>
                    <Card.Text>
                    You have successfully integrated with Five9.
                    You may update your integration information below!
                    </Card.Text>
                    <Button variant="primary" className="m-2" onClick={() => setIsUpdatingF9(true)}>Update</Button>
                    <Button variant="danger" className="m-2" onClick={() => startRemoveF9Integration()}>Remove Integration</Button>
                </Card.Body>
                </Card>
                : 
                <>
                { requiredInfo.length > 0 ? 
                  <Alert variant="danger" show={showRequiredInfo} onClose={handleShowRequiredInfo}>
                    <Alert.Heading>Required Info:</Alert.Heading>
                    <hr/>
                      { requiredInfo.includes('username') ? 
                        <h6>Five9 Username</h6>
                        :
                        null
                      }
                      { requiredInfo.includes('password') ? 
                        <h6>Five9 Password</h6>
                        :
                        null
                      }
                      { requiredInfo.includes('endpoint') ? 
                        <h6>Five9 Endpoint</h6>
                        :
                        null
                      }
                  </Alert>
                  :
                  null
                }
                <Card>
                <Card.Header>
                    Integration
                    <FontAwesomeIcon 
                    size="lg"
                    pull="right"
                    style={styles.icons.disconnected}
                    icon={faTimesCircle}  
                    />
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                    Integrate with Five9 Today!
                    </Card.Title>
                    <Card.Text>

                    

                    Our Daily Stream platform integrates with your Five9 Contact Center allowing
                    you to monitor your agent's activity in real time.
                    If you would like to begin integration, press the button below!
                    </Card.Text>
                    <Button className="m-2" variant="primary" size="small" onClick={() => setIsUpdatingF9(true)}>Integrate</Button>
                    { requiredInfo.length < 3 ?
                      <Button className="m-2" variant="danger" size="small" onClick={() => startRemoveF9Integration()}>Remove Integration</Button>
                      :
                      null
                    }
                </Card.Body>
                </Card>
                </>
                }
                </React.Fragment>
            }
          </Col>
          {/* { f9Integrated ? 
          <Col className="mb-2">
            <Card>
              <Card.Header>
                Contact Center
              </Card.Header>
              <Card.Body>
                  <Card.Title>
                  Integrate Your Five9 Contact Center!
                  </Card.Title>
                  <Card.Text>
                  To begin monitoring your Five9 Contact Center, simply import all your Five9 users with the press of a button.
                  After you have configured your Kinesis streams and Connect Instance, you can import your agents directly into Daily Stream!
                  </Card.Text>
                  <Button size="small" variant="primary" onClick={() => startImportF9Users()}>Import Users</Button>
              </Card.Body>
            </Card>
          </Col>
          : null} */}
        </Row>
        </React.Fragment>
    );
}

export default FiveNine;
