/** Possibly Deprecated */
class GlobalProps{
    constructor(){
       this.users = [];
        

      
    }




}

export default new GlobalProps()
