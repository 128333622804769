import { useEffect, useState, useRef } from 'react';
import { useHistory, Link } from "react-router-dom";
import dayjs from 'dayjs';
import Skeleton from '@mui/material/Skeleton';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import FilledInput from '@mui/material/FilledInput';
import StarIcon from '@mui/icons-material/Star';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Tooltip from '@mui/material/Tooltip';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';

const OfflineStyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#ff0000',
    color: '#ff0000',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const OnlineStyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
  },
}));

const STREAMS_PER_PAGE = 48;

export default function NewUser(props) {
  const requestAbortController = useRef(null);

  const userId = props.match.params.id;
  const [page, setPage] = useState(0); //Page number for streams
  const [loading, setLoading] = useState(true); //Loading state for streams
  const [user, setUser] = useState({ id: -1, username: '', email: '', phone: '', active: false });
  const [streams, setStreams] = useState([]);
  const [filteredStreams, setFilteredStreams] = useState([]);
  const [date, setDate] = useState(null);
  const [filter, setFilter] = useState('all'); //All, Favorited, or Unfavorited

  const history = useHistory();

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const stringAvatar = (name) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        boxShadow: (theme) => theme.shadows[1],
        height: '3.25rem',
        width: '3.25rem',
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  useEffect(() => {
    //Check if abort controller exists
    if (requestAbortController.current) {
      //Abort fetch
      requestAbortController.current.abort();
    }

    //Setup AbortController
    requestAbortController.current = new AbortController();

    const params = {
      method: 'GET',
      credentials: 'include',
      signal: requestAbortController.current.signal,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('genUserId')}`,
      },
    }

    setLoading(true);

    //Fetch User Data
    Promise.all([
      fetch(`${window.apiSite}/v1/getUser/${userId}`, params)
        .then(response => response.json())
        .then(data => {
          setUser(data);
        })
        .catch(error => {
          console.log(error);
        }),
      fetch(`${window.apiSite}/v1/getUserStreams/${userId}`, params)
        .then(response => response.json())
        .then(data => {
          setStreams(data);
          setLoading(false);
        })
        .catch(error => {
          console.log(error);
        }),
    ]);

    //Cleanup
    return () => {
      requestAbortController.current.abort();
    }
  }, []);

  useEffect(() => {
    let filteredStreams = streams;

    //Filter streams by selected filter
    switch (filter) {
      case 'favorited':
        filteredStreams = streams.filter(stream => stream.favorited);
        break;
      case 'unfavorited':
        filteredStreams = streams.filter(stream => !stream.favorited);
        break;
      default:
        break;
    }

    //Filter streams by selected date
    if (!!date) {
      filteredStreams = filteredStreams.filter(stream => {
        if (dayjs(stream.date).format('YYYY-MM-DD') === dayjs(date).format('YYYY-MM-DD')) {
          return stream;
        }
      });
    }

    //Set Filtered Streams
    setFilteredStreams(filteredStreams);
  }, [date, filter, streams]);

  return (
    <Box sx={{ flexGrow: 1, padding: 0 }}>
      <Grid container>
        <Grid item container xs={12} sm={12} sx={{ height: '20vh', backgroundColor: '#b0b0b0', alignItems: 'center' }}>
          <Grid item container spacing={2} xs={4} sm={4} justifyContent="flex-start" alignItems="center">
            <Grid item container xs={6} sm={6} justifyContent="flex-end">
              {loading && <Skeleton variant="circular" height="3.25rem" width="3.25rem"/>}
              {!loading && <Avatar {...stringAvatar(user.username)}/>}
            </Grid>
            <Grid item container xs={6} sm={6} direction="column" alignItems="flex-start">
              <Typography variant="h5" sx={{ textAlign: 'center' }}>{user?.username ? user.username : 'No User Loaded'}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2} xs={8} sm={8} justifyContent="flex-end" alignItems="center" paddingRight="2rem">
            <Grid item>
              <Link to={`/channels`}>
                <Button variant="contained" color="error">Back to Channels</Button>
              </Link>
            </Grid>
            <Grid item>
              <Button variant="contained" disabled color="primary">Subscribe</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} paddingTop="15px" paddingLeft="2rem" paddingRight="2rem">
          <Grid item container xs={4} sm={4} justifyContent="flex-start">
            <Typography variant="h2" color="primary" sx={{ fontWeight: 'bold', fontSize: '42px' }}>Past Streams</Typography>
          </Grid>
          <Grid item container xs={8} sm={8} justifyContent="flex-end" alignItems="center" spacing={2} paddingTop="2rem">
            <Grid item>
              <Select
                id="filter"
                label="Filter Streams..."
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                input={<FilledInput />}
                sx={{ height: '3.5rem' }}
              >
                <MenuItem value={'all'}>All Streams</MenuItem>
                <MenuItem disabled value={'favorited'}>Favorited</MenuItem>
                <MenuItem disabled value={'unfavorited'}>Unfavorited</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Choose a Date"
                  value={date}
                  disableFuture
                  onChange={(newValue) => setDate(newValue)}
                  sx={{
                    '& .MuiInputBase-root': {
                      height: '3.5rem',
                    },
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Tooltip title="Clear Filters" placement="top">
                <IconButton aria-label="clear filter" onClick={() => {
                  setDate(null);
                  setFilter('all');
                }}>
                  <FilterAltOffIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12} sm={12} spacing={2} paddingTop="15px" paddingLeft="2rem" paddingRight="2rem">
          { loading && Array.from(new Array(12)).map((item, i) => {
            return (
              <Grid item xs={2} sm={2} key={i}>
                <Skeleton variant="rounded" height="300px" width="100%"/>
              </Grid>
            );
          })}
          { !loading && filteredStreams.slice(page * STREAMS_PER_PAGE, (page + 1) * STREAMS_PER_PAGE).map(stream => (
            <Grid item xs={2} sm={2} key={stream.id}>
              <Card>
                <CardHeader
                  sx={{
                    '& .MuiCardHeader-title': {
                      fontSize: '1.5rem',
                    },
                    '& .MuiCardHeader-subheader': {
                      fontSize: '1rem',
                    },
                    '& .MuiCardHeader-action': {
                      alignSelf: 'center',
                    },
                  }}
                  action={
                    <Tooltip title="Favorite the Stream for later!" placement="top">
                      <span>
                        <IconButton disabled aria-label="favorite">
                          <StarIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  }
                  title={user.username}
                  subheader={dayjs(stream.date).format('MMMM D, YYYY')}
                />
                <CardMedia
                  component="img"
                  image="https://cdn.dailystream.com/images/logo.png"
                  alt="DailyStream Logo"
                />
                <CardActions>
                  <Grid container spacing={1} justifyContent="flex-end" alignItems="center">
                    <Grid item>
                      {/* Create text for idle percentage of stream */}
                      <Typography variant="body1" color="text.secondary">
                        <strong>{stream?.idlePercent ? stream.idlePercent : 'No Idle Data'}</strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={() => history.push(`/user/${user.id}/${stream.date}/0`)}>Watch</Button>
                    </Grid>
                  </Grid>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid item container xs={12} sm={12} justifyContent="space-evenly" alignItems="center" paddingTop="35px" paddingBottom="15px">
          <Grid item >
            <Button variant="contained" color="warning" onClick={() => setPage(page - 1)}>Load Previous</Button>
          </Grid>
          <Grid item>
            <Typography variant="body1" color="text.secondary">
              {/** Page of x of total  */}
              <strong>{`${page + 1} of ${Math.ceil(filteredStreams.length / STREAMS_PER_PAGE)}`}</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => setPage(page + 1)}>Load More</Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
