import { compose, withState, withHandlers } from 'react-recompose';
import Viewer from '../Viewer';
import Recorder from '../Recorder';

export default function shareClipMentions(value) {
	return compose(
		withState('value', 'setValue', value),
		withHandlers({
		onChange: ({ setValue }) => (ev, newValue) => {
      const value = ev.target.value;
      const regex = /[^{}]+(?=})/g;
      const mentions = value.match(regex);

      Recorder.mentions = mentions;
      Recorder.comment = newValue;
      setValue(newValue);
		},
		onAdd: () => (...args) => { },
		onRemove: () => (...args) => { }
		})
	)
}
