import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Auth from './Auth'
import Navbar from '../Fragments/Navbar'
import Footer from '../Fragments/Footer'

export const ProtectedRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={ 
      props => {
        Auth.verifyLogin()
        if (Auth.isAuthenticated()) {
          return (
            <React.Fragment>
              <Navbar />
              <Component {...props} />
              <Footer />
            </React.Fragment>
          )
        } else {
          return <Redirect to={{pathname:"/", state: { from: props.location }}}/>
        }
      }
    }/>
  )
}