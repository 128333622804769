import {
	Link,
	useHistory
} from 'react-router-dom';
import {
	faFolder,
	faLock,
	faFileVideo,
	faArrowCircleLeft,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FolderObject from './FolderObject';
import Container from 'react-bootstrap/Container';

const styles = {
	spinner: {
		height: "5rem",
		width: "5rem"
	},
	colors: {
		primary: {
			color: "#007bff"
		},
		danger: {
			color: "#dc3545"
		}
	}
}

function Folder(props) {
	let history = useHistory()

	const addParentObject = (id) => {
		FolderObject.addHistoryItem(id);
		let url = `/library/${id}`;
		history.push(url);
	}

	const removeParentObject = (e) => {
		if (FolderObject.folderHistory.length > 1) {
			FolderObject.removeHistoryItem();
			let url = `/library/${FolderObject.folderHistory[FolderObject.folderHistory.length - 1]}`;
			history.push(url);
		}
		e.preventDefault();
	}

	let folders = []
	if (props.folders) {
		for (let data of props.folders) {
			if (data.isArchived || data.isRecycled) {
				folders.push(
					<div key={data.id} id={data.id} data-authlevel={data.authLevel} className="tile folder">
						<FontAwesomeIcon id={data.id} icon={faFolder} className="folderIcons" style={styles.colors.primary} />
						<h3 id={data.id}>{data.name}</h3>
					</div>
				)
			} else if (data.isLocked) {
				folders.push(
					<div key={data.id} id={data.id} data-authlevel={data.authLevel} onClick={() => { addParentObject(data.id) }} className="tile folder">
						<FontAwesomeIcon id={data.id} icon={faLock} className="lockedIcon" style={styles.colors.danger} />
						<FontAwesomeIcon id={data.id} icon={faFolder} className="folderIcons" style={styles.colors.primary} />
						<h3 id={data.id}>{data.name}</h3>
					</div>
				)
			} else {
				folders.push(
					<div key={data.id} id={data.id} data-authlevel={data.authLevel} onClick={() => { addParentObject(data.id) }} className="tile folder">
						<FontAwesomeIcon id={data.id} icon={faFolder} className="folderIcons" style={styles.colors.primary} />
						<h3 id={data.id}>{data.name}</h3>
					</div>
				)
			}
		}
	}

	let clips = []
	if (props.files) {
		for (let data of props.files) {
			let url = `/clip/${data.id}/${data.UID}`
			if (data.isLocked) {
				clips.push(
					<div key={`${data.id}-clip`} id={data.id} data-authlevel={data.authLevel} className="tile form">
						<Link to={url}>
							<FontAwesomeIcon id={data.id} icon={faLock} className="lockedIcon" style={styles.colors.danger} />
							<FontAwesomeIcon id={data.id} icon={faFileVideo} className="folderIcons" style={styles.colors.primary} />
							<h3 id={data.id}>{data.name}</h3>
						</Link>
					</div>
				)
			} else {
				clips.push(
					<div key={`${data.id}-clip`} id={data.id} data-authlevel={data.authLevel} className="tile form">
						<Link to={url}>
							<FontAwesomeIcon id={data.id} icon={faFileVideo} className="folderIcons" style={styles.colors.primary} />
							<h3 id={data.id}>{data.name}</h3>
						</Link>
					</div>
				)
			}
		}
	}

	useEffect(() => {
		if (FolderObject.folderHistory.length < 1) {
			FolderObject.addHistoryItem(props.currentFolder);
		}
	}, []);

	return (
		<Container>
			<div className="folderControl">
				<h4>
					<FontAwesomeIcon
						className="ml-1 mr-1"
						onClick={removeParentObject}
						icon={faArrowCircleLeft}
						style={styles.colors.primary}
					/>
					<span className="ml-2">{props.name}</span>
				</h4>
			</div>
			{folders?.length > 0 &&
				<>
					<h5>Folders: </h5>
					<div className="folder-wrap level-current scrolling">
						{folders}
					</div>
				</>
			}
			{clips?.length > 0 &&
				<>
					<br></br>
					<h5>Clips: </h5>
					<div className="folder-wrap level-current scrolling">
						{clips}
					</div>
				</>
			}
			{folders?.length <= 0 && clips?.length <= 0 &&
				<>
					<h5>This folder is empty :&#40;</h5>
				</>
			}
		</Container>
	)
}

export default Folder
