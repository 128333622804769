import { 
	Container,
	Alert
} from 'react-bootstrap';
import Auth from '../Components/Auth';
import React, {
	useState, 
	useEffect,
	useLayoutEffect
} from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loader from '../Fragments/Loader';
import SystemSettings from '../Fragments/Admin/SystemSettings';
import ManageUsers from '../Fragments/Admin/ManageUsers';
import CallCenterSetup from '../Components/CallCenterSetup';
import Api from '../Fragments/Admin/Api';
import Groups from '../Fragments/Admin/Groups';
import Billing from '../Fragments/Admin/Billing';

function Admin() {
	const [isUnavailable, setIsUnavailable] = useState(true);
	const [errorMessage, setErrorMessage] = useState(<Loader />);
  const [tabIndex, setTabIndex] = useState(0)

	useLayoutEffect(() => {
		if (!Auth.isAdmin()) {
			setIsUnavailable(true);
			setErrorMessage(
				<Alert variant="danger">
				<Alert.Heading>No Users Available!</Alert.Heading>
					<p>
					Oh no! It looks like the users you requested are 
					unavailable or cannot be viewed at this time. 
					If you believe this is an error, please report 
					this information to your Systems Administrator.
				</p>
				</Alert>
			);
			window.location.href = '/'
		}
	});

	useEffect(() => {
		setIsUnavailable(false);
	}, []);

	return isUnavailable ? (
		<React.Fragment>
		<Container>
			{errorMessage}
		</Container>
		</React.Fragment>
	) : (
		<React.Fragment>
		<Container className="mt-2">
			<Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
			<TabList>
				<Tab>System Settings</Tab>
				<Tab>Groups</Tab>
				<Tab>Users</Tab>
				<Tab>Call Center Setup</Tab>
				<Tab>Daily Stream API</Tab>
				{/* <Tab disabled>Billing</Tab> */}
			</TabList>
			<TabPanel>
				<SystemSettings/>
			</TabPanel>
      <TabPanel>
        <Groups />
      </TabPanel>
			<TabPanel>
        <ManageUsers
          setTabIndex={setTabIndex}
        />
			</TabPanel>
			<TabPanel>
				<CallCenterSetup/>
			</TabPanel>
			<TabPanel>
				<Api/>
			</TabPanel>
			{/* <TabPanel>
				<Billing/>
			</TabPanel> */}
			</Tabs>
		</Container>
		</React.Fragment>
	);
}

export default Admin;
