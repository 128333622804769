import {
	sendData, 
	socket,
	joinRoom,
	leaveRoom
} from '../Hooks/Socket'

class Auth {
	constructor() {
    socket.on(`connect_error`, (err) => {
      if(err?.message === `Unauthorized Login`) {
        window.localStorage.clear();
        window.location.href = `https://dailystream.com/login`;
      }
    });
	}

	logout(cb) {
		leaveRoom(`individual-${localStorage.getItem('userId')}`);

		sendData('logout', {genUserId: localStorage.getItem('genUserId')}); 

		cb();
	}

	isAuthenticated() {
		return localStorage.getItem('genUserId')
	}

	verifyLogin() {
		localStorage.setItem('genUserId', this.getCookie('genUserId'));
		localStorage.setItem('userId', this.getCookie('userId'));
		localStorage.setItem('width', this.getCookie('width'));
		localStorage.setItem('height', this.getCookie('height'));
		localStorage.setItem('frameRate', this.getCookie('frameRate'));
		localStorage.setItem('sampleSize', this.getCookie('sampleSize'));
		localStorage.setItem('sampleRate', this.getCookie('sampleRate'));
		localStorage.setItem('audioBitsPerSecond', this.getCookie('audioBitsPerSecond'));
		localStorage.setItem('videoBitsPerSecond', this.getCookie('videoBitsPerSecond'));
		localStorage.setItem('authLevel', this.getCookie('authLevel'));
		localStorage.setItem('notified', 0);
		localStorage.setItem('monitors', this.getCookie('monitors'));
		localStorage.setItem('channel', this.getCookie('channel'));
		localStorage.setItem('idleTracking', this.getCookie('idleTracking'));
		localStorage.setItem('warnUser', this.getCookie('warnUser'));
    localStorage.setItem('companyId', this.getCookie('companyId'));
    localStorage.setItem('recordAudio', this.getCookie('recordAudio'));
		joinRoom(`individual-${this.getCookie('userId')}`);
		joinRoom(this.getCookie('channel'));
	}

	authLevel = () => {
		return parseInt(localStorage.getItem('authLevel'));
	}

	isAdmin = () => {
		return parseInt(localStorage.getItem('authLevel')) >= 8;
	}
 
	getNotified = () => {
		const n = localStorage.getItem('notified');
		return parseInt(n);
	}

	setNotified = () => {
		return localStorage.setItem('notified', 1);
	}

	setCookie = (cname, cvalue, exdays) => {
		const d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		let expires = "expires="+ d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/; domain=dailystream.com";
	}
  
	getCookie = (cname) => {
		let name = cname + "=";
		let decodedCookie = decodeURIComponent(document.cookie);
		let ca = decodedCookie.split(';');
		for (let i = 0; i <ca.length; i++) {
			let c = ca[i];
			// eslint-disable-next-line eqeqeq
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			// eslint-disable-next-line eqeqeq
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}
}

export default new Auth();
