import React from 'react';
import ReactLoading from 'react-loading';
 
const Loader = () => (
	<div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}}>
		<ReactLoading type={'spin'} color={'#0d6efd'} height={'20%'} width={'20%'} />
	</div>
);
 
export default Loader;
