import { 
	FontAwesomeIcon 
} from '@fortawesome/react-fontawesome';
import { 
	faTimesCircle,
	faCheckCircle,
	faEyeSlash,
	faEye
} from '@fortawesome/free-solid-svg-icons';
import React, { 
	useState, 
	useEffect,
} from 'react';
import Loader from '../Loader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Modal from 'react-bootstrap/Modal';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { sendData, onData, offData } from '../../Hooks/Socket';


const styles = {
	colors: {
		primary: {
			color: "#007bff"
		}
	},
	hidden: {
		display: 'none'
	},
	visible: {
		display: 'table-cell'
	},
	font: {
		fontFamily: 'Roboto, Helvetica, sans-serif'
	},
	table: {
		maxHeight: '400px !important'
	},
	card: {
		header: {
			backgroundColor: '#727cf5',
			color: '#fff'
		}
	},
	icons: {
		connected: {
			color: '#10b759',
		},
		disconnected: {
			color: '#ff3366'
		},
		save: {
			color: '#10b759',
			fontSize: '1.2rem',
			cursor: 'pointer',
			marginRight: '10px'
		},
		cancel: {
			color: '#fbbc06',
			fontSize: '1.2rem',
			cursor: 'pointer'
		},
		edit: {
			color: '#007bff',
			fontSize: '1.2rem',
			cursor: 'pointer'
		},
		danger: {
			color: '#ff3366',
			fontSize: '1.2rem',
			cursor: 'pointer'
		}
	},
	alignMiddle: {
		alignItems: "center"
	},
	spinner: {
		height: "5rem",
		width: "5rem"
	}
}

function Api() {
    const [isLoading, setIsLoading] = useState(true);
    const [isDailyStreamIntegrated, setIsDailyStreamIntegrated] = useState(false);
    const [apiKey, setApiKey] = useState(null);
    const [showingApiKey, setShowingApiKey] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState({
        title: '',
        text: '',
        confirmButton: '',
        confirm: null,
        confirmHandler: null,
        cancelButton: '',
        cancel: null,
        static: false
    });
    
    const handleShowAlertModal = () => setShowAlertModal(!showAlertModal);

    const getDailyStreamIntegration = () => {
		let data = {
			genUserId: localStorage.getItem('genUserId')
		}
		sendData('getDailyStreamIntegration', data);
    }

    /**
     * Handles response to getting
     * Daily Stream integration data
     * @param {JSON} data response data 
     */
    const onGetDailyStreamIntegration = (data) => {
		if (data.integrated) {
			setIsDailyStreamIntegrated(true);
			setApiKey(data.apiKey);
			setIsLoading(false);
		} else {
			setIsDailyStreamIntegrated(false);
			setIsLoading(false);
		}
    }

    const startRequestApiKey = () => {
		setAlertModalContent({
			title: 'Request Api Key?',
			text: 'Are you sure you would like to request an api key? BE AWARE: Requesting an api key will also overwrite any old key',
			confirm: true,
			confirmButton: 'Confirm',
			confirmHandler: requestApiKey,
			cancel: true,
			cancelButton: 'Cancel',
			static: false
		});
		handleShowAlertModal();
    }

    const requestApiKey = () => {
		handleShowAlertModal();

		let data = {
			genUserId: localStorage.getItem('genUserId')
		}
		sendData('requestApiKey', data);
    }

    /**
     * Handles response to request
     * for new API key
     * @param {JSON} data response data
     */
    const onRequestApiKey = (data) => {
		setApiKey(data.apiKey);
		setAlertModalContent({
			title: 'Api Key',
			text: <InputGroup size="sm" className="mt-2">
				<FormControl type="text" value={data.apiKey} readOnly/>
				<OverlayTrigger
					placement="top"
					trigger="click"
					delay={{ hide: 400 }}
					overlay={renderTooltip({message: 'Copied!'})}
				>
				<Button variant="outline-secondary" onClick={() => copyText(data.apiKey)}>Copy </Button>
				</OverlayTrigger>
				</InputGroup>,
			confirm: false,
			cancel: true,
			cancelButton: 'Close',
			static: false
		})
		handleShowAlertModal();

		getDailyStreamIntegration();
    }

    const startDeleteApiKey = () => {
		setAlertModalContent({
			title: 'Delete Api Key?',
			text: 'Are you sure you would like to delete your api key? You may request a new one at any time.',
			confirm: true,
			confirmButton: 'Confirm',
			confirmHandler: deleteApiKey,
			cancel: true,
			cancelButton: 'Cancel',
			static: false
		});
		handleShowAlertModal();
    }

    const deleteApiKey = () => {
		handleShowAlertModal();
		setApiKey(null);

		let data = {
			genUserId: localStorage.getItem('genUserId')
		}
		sendData('deleteApiKey', data);
    }

    /**
     * Handles respone to request to
     * delete API key
     * @param {JSON} data response from 
     */
    const onDeleteApiKey = (data) => {
		setAlertModalContent({
			title: 'Success!', 
			text: 'You have successfully deleted your api key.',
			confirm: false,
			cancel: true,
			cancelButton: 'Close',
			static: false
		});
		handleShowAlertModal();

		getDailyStreamIntegration();
    }

    const copyText = (text) => {
		navigator.clipboard.writeText(text);
    }

    const renderTooltip = (props) => {
		return (
			<Tooltip id="default-tooltip">
			{props.message}
			</Tooltip>
		);
    }

    useEffect(() => {
        getDailyStreamIntegration();

        onData('getDailyStreamIntegration', onGetDailyStreamIntegration);
        onData('requestApiKey', onRequestApiKey);
        onData('deleteApiKey', onDeleteApiKey);

		return () => {
			offData('getDailyStreamIntegration');
			offData('requestApiKey');
			offData('deleteApiKey');
		}
    }, []);

    return isLoading ? (
        <Loader/>
    ) : ( 
      <React.Fragment>
        <Modal backdrop={alertModalContent.static ? "static" : true} show={showAlertModal} onHide={handleShowAlertModal}>
            <Modal.Header>
            <Modal.Title>{alertModalContent.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{alertModalContent.text}</Modal.Body>
            <Modal.Footer>
            {alertModalContent.confirm ? 
              <Button variant="primary" onClick={() => alertModalContent.confirmHandler()}>
                  {alertModalContent.confirmButton}
              </Button>
            : 
              null
            }
            {alertModalContent.cancel ? 
              <Button variant="danger" onClick={handleShowAlertModal}>
                  {alertModalContent.cancelButton}
              </Button>
            : 
              null
            }
            </Modal.Footer>
        </Modal>
      
        <Row> 
            <Col>
            { isDailyStreamIntegrated ? 
            <Card>
              <Card.Header>
                Daily Stream Integration
                <FontAwesomeIcon 
                  size="lg"
                  pull="right"
                  style={styles.icons.connected}
                  icon={faCheckCircle}  
                />
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  You're all set!
                </Card.Title>
                <Card.Text className="m-1">
                  Below you will find your api key. If you believe your key has been compromised,
                  request a new one immediately. BE AWARE: Requesting a new api key will overwrite the existing one!
                  <InputGroup size="sm" className="mt-2">
                  <FormControl type={showingApiKey ? "text" : "password"} value={apiKey} readOnly/>
                  <InputGroup.Prepend>
                    <Button variant="outline-secondary" onClick={() => setShowingApiKey(!showingApiKey)}>
                      <FontAwesomeIcon 
                        size="lg"
                        icon={showingApiKey ? faEye : faEyeSlash}  
                      />
                    </Button>
                    <OverlayTrigger
                      placement="top"
                      trigger="click"
                      overlay={renderTooltip({message: 'Copied!'})}
                    >
                      <Button variant="outline-secondary" onClick={() => copyText(apiKey)}>Copy </Button>
                    </OverlayTrigger>
                  </InputGroup.Prepend>
                  </InputGroup>
                </Card.Text>
                <Button className="m-1" variant="primary" size="small" onClick={() => startRequestApiKey()}>Request New Key</Button>
                <Button className="m-1" variant="danger" size="small" onClick={() => startDeleteApiKey()}>Delete Old Key</Button>
              </Card.Body>
            </Card>
            : 
            <Card>
              <Card.Header>
                Daily Stream Integration
                <FontAwesomeIcon 
                  size="lg"
                  pull="right"
                  style={styles.icons.disconnected}
                  icon={faTimesCircle}  
                />
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  Integrate with Daily Stream Today!
                </Card.Title>
                <Card.Text>
                  Our Daily Stream platform integrates with various services.
                  If you would like to begin integrating with Daily Stream, please
                  request your unique api key below!
                </Card.Text>
                <Button variant="primary" size="small" onClick={() => requestApiKey()}>Request Key</Button>
              </Card.Body>
            </Card>
            }
          </Col>
        </Row>
      </React.Fragment>
    );
}

export default Api;
