const socket = window.socket

const joinRoom = (room) => {
	socket.emit('create', room);
}

const leaveRoom = (room) => {
	socket.emit('leave', room)
}

socket.on('joined', (data) => {
});

const sendData = (proc, data) => {
    socket.emit(proc, data);
}

const onData = (proc, cb) => {
    socket.on(proc, (data) => {
        cb(data);
    });
}

const offData = (proc) => {
	socket.off(proc);
}

export {
	socket,  
	joinRoom, 
	leaveRoom,
	sendData,
	onData,
	offData
}
