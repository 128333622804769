import { 
	Container,
	Row
} from 'react-bootstrap'

function E404() {
	return (
		<Container>
		<Row className="justify-content-center">
			<h1 className="font-weight-bold mt-2 tx-80 text-muted">404</h1>
		</Row>
		<Row className="justify-content-center">
			<h4 className="mb-2">Page Not Found</h4>
		</Row>
		<Row className="justify-content-center">
			<h6 className="text-muted mb-3 text-center">Oopps!! The page you were looking for doesn't exist.</h6>
		</Row>
		</Container>
	);
}

export default E404
