import { sendData, onData } from "../Hooks/Socket";
import logger from "./Logger";
const FILE_NAME = "Users.js";

class Users {
  constructor() {
    this.usersArray = [];
    this.checkUsersLiveIntervalMS = 35000;
    this.checkUsersLiveInterval = null;

    // onData("getActiveUsers", this.onGetActiveUsers);
    // onData("idleStateChange", this.onStateChange);
  }

  // getActiveUsers = () => {
  //   let getUsersData = {
  //     genUserId: localStorage.getItem("genUserId"),
  //   };

  //   sendData("getActiveUsers", getUsersData);
  // };

  // onGetActiveUsers = (data) => {
  //   console.log(data);
  //   data.users.forEach((user) => {
  //     let lastActiveDate = user.lastActive.replace("Z", "-07:00");
  //     let lastActiveTime = new Date(lastActiveDate).getTime();
  //     user.lastActive = lastActiveTime;

  //     this.usersArray.push(user);
  //   });
  // };

  setUsers = () => {
    // this.usersArray.forEach((user) => {
    //   this.updateUserLive(user.id);
    //   this.updateUserState(user.id, user.state);
    // });

    // this.checkUsersLiveInterval = setInterval(() => {
    //   this.checkUsersLive();
    // }, this.checkUsersLiveIntervalMS);
  };

  checkUsersLive = () => {
    // console.log("Checking Users Live");
    // this.usersArray.forEach((user) => {
    //   this.updateUserLive(user.id);
    // });
  };

  updateUserLive = (id) => {
    // try {
    //   let liveElement = document.getElementById(`agentLive${id}`);
    //   let linkElement = document.getElementById(`agentLink${id}`);
    //   let userIndex = this.usersArray.findIndex((user) => user.id == id);
    //   let live =
    //     new Date().getTime() - this.usersArray[userIndex].lastActive < 35000
    //       ? true
    //       : false;

    //   if (live) {
    //     liveElement.classList.remove("hidden");
    //     linkElement.style.pointerEvents = "auto";
    //     linkElement.style.textDecoration = "underline";
    //     linkElement.style.color = "blue";
    //   } else {
    //     liveElement.classList.add("hidden");
    //     linkElement.style.pointerEvents = "none";
    //     linkElement.style.textDecoration = "none";
    //     linkElement.style.color = "black";
    //   }
    // } catch (err) {
    //   //console.error(err);
    // }
  };

  updateUserState = (id, state) => {
    // try {
    //   let agentIdle = document.getElementById(`agentIdle${id}`);

    //   if (state) {
    //     agentIdle.classList.add("hidden");
    //   } else {
    //     agentIdle.classList.remove("hidden");
    //   }
    // } catch (err) {
    //   //console.error(err);
    // }
  };

  onStateChange = (data) => {
    // let id = parseInt(data.id);
    // let userIndex = this.usersArray.findIndex((users) => users.id == id);

    // this.usersArray[userIndex].state = data.state;

    // this.updateUserState(id, data.state);
  };
}

export default new Users();
