import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom'
import Auth from '../Components/Auth'
import { socket } from '../Hooks/Socket';

function Logout() {
	const history = useHistory();

	useEffect(() => {
		Auth.logout(() => { 

      fetch(`${window.apiSite}/logout`, {
        method: "POST",
        credentials: "include",
      });
		  Auth.setCookie('genUserId', "", -1);
		  Auth.setCookie('userId', "", -1);
		  Auth.setCookie('width', "", -1);
		  Auth.setCookie('height', "", -1);
		  Auth.setCookie('frameRate', "", -1);
		  Auth.setCookie('sampleSize', "", -1);
		  Auth.setCookie('sampleRate', "", -1);
		  Auth.setCookie('audioBitsPerSecond', "", -1);
		  Auth.setCookie('videoBitsPerSecond', "", -1);
		  Auth.setCookie('companyId', "", -1);
		  Auth.setCookie('authLevel', "", -1);
		  Auth.setCookie('monitors', "", -1);
		  Auth.setCookie('url', "", -1);
		  Auth.setCookie('channel', "", -1);
		  Auth.setCookie('idleTracking', "", -1);

  		window.localStorage.clear();
      socket.disconnect();
      //this connect is supposed to fail, as to trigger the logout
      socket.connect();
		})
	}, []);

	return ( 
		<div className="main-wrapper"></div>
	);
}

export default Logout;
