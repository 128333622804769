class Fetch {
    constructor() {
        this.url = window.apiSite + '/v1/';
    }
    fetchData = async (urlSegment, method = 'POST', data = {}) => {
        let url = this.url + urlSegment;
        let request = {
            method: method,
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('genUserId')
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        }
        if(method == 'POST' || method == 'PUT' || method == 'DELETE'){
            if(!this.isEmpty(data)){
                request.body = JSON.stringify(data);
            }
        }
        const response = await fetch(url, request);
        if(response.status != 200){
            console.error(response.statusText);
            return false;
        }else{
            let parsedJason = await response.json();
            return parsedJason;
        }
    }

    isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }
}
export default new Fetch();
