import React, { useCallback, useEffect } from "react";
import useState from 'react-usestateref';
import { useHistory } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { sendData } from '../Hooks/Socket'
import FolderObject from './FolderObject';
import {
	faFolderPlus,
	faPenSquare,
	faUsers,
	faFileExport,
	faShieldAlt,
	faExchangeAlt,
	faSearch,
	faLock,
	faArchive,
	faTrash,
	faFolderOpen,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function LibContextMenu(props) {
	const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
	const [show, setShow] = useState(false);
	const [foldersLocation, setFoldersLocation, foldersLocationRef] = useState(0);
	const [currentFolder, setCurrentFolder, currentFolderRef] = useState(null);
	const [folderAuthLevel, setFolderAuthLevel, folderAuthLevelRef] = useState(null);
	const [newFolderName, setNewFolderName, newFolderNameRef] = useState('');
	const [newOwner, setNewOwner, newOwnerRef] = useState('');
	const [newAuthLevel, setNewAuthLevel, newAuthLevelRef] = useState('');
	const [selectedItemId, setSelectedItemId, selectedItemIdRef] = useState('');
	const [selectedGroupId, setSelectedGroupId, selectedGroupIdRef] = useState('');
	const [selectedItemName, setSelectedItemName, selectedItemNameRef] = useState('');
	const [isFolder, setIsFolder, isFolderRef] = useState(false);
	const [isClip, setIsClip, isClipRef] = useState(false);
	const [isTreeView, setIsTreeView, isTreeViewRef] = useState(false);
	const [groups, setGroups, groupsRef] = useState([]);
	const [users, setUsers, usersRef] = useState([]);
	const [showModal, setShowModal, showModalRef] = useState(false);
	const handleShowModal = () => setShowModal(!showModalRef.current);
	const [modal, setModal] = useState({
		title: '',
		text: '',
		confirmButton: '',
		confirm: null,
		confirmHandler: null,
		cancelButton: '',
		cancel: null,
		static: false,
		hideEvent: null
	});

	let history = useHistory();

	const addParentObject = (id) => {
		FolderObject.addHistoryItem(id);
		let url = `/library/${id}`;
		history.push(url);
	}

	const createFolder = (event) => {
		if (newFolderNameRef.current.length > 0) {
			event.preventDefault();
			sendData('addFolder', {
				folderName: newFolderNameRef.current,
				parentFolder: currentFolderRef.current,
			});
			handleShowModal();
			setNewFolderName('');
		}
	}

	const renameFolderClip = (event) => {
		if (newFolderNameRef.current.length > 0) {
			event.preventDefault();
			sendData('renameFolder', {
				isFolder: isFolderRef.current,
				itemId: selectedItemIdRef.current,
				itemName: newFolderNameRef.current,
			});
			handleShowModal();
			setNewFolderName('');
		}
	}

	const addItemToGroup = (event) => {
		event.preventDefault();
		sendData('addItemToGroup', {
			isFolder: isFolderRef.current,
			itemId: selectedItemIdRef.current,
			groupId: selectedGroupIdRef.current,
		});
		handleShowModal();
	}

	const authLevelFolderClip = (event) => {
		event.preventDefault();
		sendData('authLevelChange', {
			isFolder: isFolderRef.current,
			itemId: selectedItemIdRef.current,
			authLevel: newAuthLevelRef.current,
		});
		handleShowModal();
	}

	const transferOwner = (event) => {
		event.preventDefault();
		sendData('transferOwner', {
			isFolder: isFolderRef.current,
			itemId: selectedItemIdRef.current,
			newOwnerId: newOwnerRef.current,
		});
		handleShowModal();
	}

	const lockFolderClip = (event) => {
		event.preventDefault();
		sendData('lockFolder', {
			isFolder: isFolderRef.current,
			itemId: selectedItemIdRef.current,
		});
		handleShowModal();
	}

	const archiveFolderClip = (event) => {
		event.preventDefault();
		sendData('archiveFolder', {
			isFolder: isFolderRef.current,
			itemId: selectedItemIdRef.current,
		});
		handleShowModal();
	}

	const recycleFolderClip = (event) => {
		event.preventDefault();
		sendData('recycleFolder', {
			isFolder: isFolderRef.current,
			itemId: selectedItemIdRef.current,
		});
		handleShowModal();
	}

	const folderClipMenuOptions = () => {
		document.getElementById('rename').addEventListener("click", () => {
			setModal({
				title: "Rename Item",
				text: <>
					<Form>
						<Form.Label>New Item Name:</Form.Label>
						<Form.Control
							required
							placeholder={selectedItemNameRef.current}
							onChange={e => setNewFolderName(e.target.value)}
						/>
					</Form>
				</>,
				confirm: true,
				confirmButton: "Rename",
				cancel: true,
				confirmHandler: renameFolderClip,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
		document.getElementById('groups').addEventListener("click", () => {
			setSelectedGroupId(groupsRef.current[0].id);
			setModal({
				title: "Add Item to Group",
				text: <>
					<Form>
						<Form.Label>Select a group to add this item to:</Form.Label>
						<Form.Control as="select" onChange={e => setSelectedGroupId(e.target.value)}>
							{groupsRef.current.map((group) => (
								<option key={group.id} value={group.id}>{group.name}</option>
							))}
						</Form.Control>
					</Form>
				</>,
				confirm: true,
				confirmButton: "Add",
				cancel: true,
				confirmHandler: addItemToGroup,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
		document.getElementById('move').addEventListener("click", () => {
			setModal({
				title: "Move Item",
				text: "",
				confirm: true,
				confirmButton: "Confirm",
				cancel: true,
				// confirmHandler: moveFolderClip, or whatever function to call on confirm button click
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
		document.getElementById('authlevel').addEventListener("click", () => {
			setNewAuthLevel(String(folderAuthLevelRef.current));
			setModal({
				title: "Select New Authorization Level:",
				text: <>
					<Form>
						<Form.Label>Changing the authorization level will update who can view/access this item.</Form.Label>
						<Form.Control as="select" defaultValue={String(folderAuthLevelRef.current)} onChange={e => setNewAuthLevel(e.target.value)}>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="6">6</option>
							<option value="7">7</option>
							<option value="8">8</option>
							<option value="9">9</option>
							<option value="10">10</option>
						</Form.Control>
					</Form>
				</>,
				confirm: true,
				confirmButton: "Update",
				cancel: true,
				confirmHandler: authLevelFolderClip,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
		document.getElementById('transferowner').addEventListener("click", () => {
			setNewOwner(usersRef.current[0].id);
			setModal({
				title: "Transfer Ownership",
				text: <>
					<Form>
						<Form.Label>Select a new owner:</Form.Label><p />
						{/* <FontAwesomeIcon icon={faSearch} className="searchIcon" />
						<Form.Control
							className="userSearchBar"
							onChange={e => filterUsersList(e.target.value)}
						/>
						^^^^^^^ Code for searching through users list */}
						<Form.Control as="select" onChange={e => setNewOwner(e.target.value)}>
							{usersRef.current.map((user) => (
								<option key={user.id} value={user.id}>{user.firstName} {user.lastName}</option>
							))}
						</Form.Control>
					</Form>
				</>,
				confirm: true,
				confirmButton: "Confirm",
				cancel: true,
				confirmHandler: transferOwner,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
		document.getElementById('lock').addEventListener("click", () => {
			setModal({
				title: "Toggle Lock?",
				text: "Locking an item prevents it from being modified in any way.",
				confirm: true,
				confirmButton: "Confirm",
				cancel: true,
				confirmHandler: lockFolderClip,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
		document.getElementById('archive').addEventListener("click", () => {
			setModal({
				title: "Archive Item?",
				text: "WARNING: Any subfolders or items within this folder will also be archived.",
				confirm: true,
				confirmButton: "Archive",
				cancel: true,
				confirmHandler: archiveFolderClip,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
		document.getElementById('recycle').addEventListener("click", () => {
			setModal({
				title: "Recycle Item?",
				text: "WARNING: Any subfolders or items within this folder will also be recycled, and after 30 days this cannot be undone.",
				confirm: true,
				confirmButton: "Recycle",
				cancel: true,
				confirmHandler: recycleFolderClip,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
	}

	const archivedMenuOptions = () => {
		document.getElementById('archive').addEventListener("click", () => {
			setModal({
				title: "Unarchive Item",
				text: "Place this item back with the active items?",
				confirm: true,
				confirmButton: "Unarchive",
				cancel: true,
				confirmHandler: archiveFolderClip,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
	}

	const recycledMenuOptions = () => {
		document.getElementById('recycle').addEventListener("click", () => {
			setModal({
				title: "Unrecycle Item",
				text: "Place this item back with the active items?",
				confirm: true,
				confirmButton: "Unrecycle",
				cancel: true,
				confirmHandler: recycleFolderClip,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
	}

	const defaultMenuOptions = () => {
		document.getElementById('create').addEventListener("click", () => {
			setModal({
				title: "Create Folder",
				text: <>
					<Form>
						<Form.Label>Folder Name:</Form.Label>
						<Form.Control
							required
							placeholder="My Clips"
							onChange={e => setNewFolderName(e.target.value)}
						/>
					</Form>
				</>,
				confirm: true,
				confirmButton: "Create",
				cancel: true,
				confirmHandler: createFolder,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
	}

	const treeViewMenuOptions = () => {
		document.getElementById('open').addEventListener("click", () => {
			addParentObject(selectedItemIdRef.current);
		});

		document.getElementById('create').addEventListener("click", () => {
			setModal({
				title: "Create Folder",
				text: <>
					<Form>
						<Form.Label>Folder Name:</Form.Label>
						<Form.Control
							required
							placeholder="My Clips"
							onChange={e => setNewFolderName(e.target.value)}
						/>
					</Form>
				</>,
				confirm: true,
				confirmButton: "Create",
				cancel: true,
				confirmHandler: createFolder,
				cancelButton: "Cancel",
			});
			handleShowModal();
		});
	}

	const handleContextMenu = useCallback(
		(event) => {
			event.preventDefault();
			setAnchorPoint({ x: event.pageX, y: event.pageY });
			setSelectedItemName('');
			setIsFolder(false);
			setIsClip(false);
			setIsTreeView(false);
			setShow(false);
			setShow(true);

			if (document.getElementById('folders').contains(event.target)) {
				if (event.target.id && event.target.id != 'folders') {
					setSelectedItemId(event.target.id);
					setSelectedItemName(event.target.innerText);
					setFolderAuthLevel(document.getElementById(event.target.id).dataset.authlevel);

					if (event.target.className == 'tile folder' || event.target.parentElement.className == 'tile folder') {
						setIsFolder(true);
					} else {
						setIsClip(true);
					}
				} else if (event.target.parentElement.id) {
					setSelectedItemId(event.target.parentElement.id);
					setSelectedItemName(event.target.parentElement.parentElement.innerText);
					setFolderAuthLevel(document.getElementById(event.target.parentElement.id).dataset.authlevel);

					if (event.target.parentElement.parentElement.className == 'tile folder') {
						setIsFolder(true);
					} else {
						setIsClip(true);
					}
				}
			} else if (document.getElementById('treeview').contains(event.target)) {
				if (event.target.className === 'MuiTreeItem-label') {
					setSelectedItemId(event.target.parentElement.parentElement.id);
					setIsTreeView(true);
				}
			}

			if ((isFolderRef.current || isClipRef.current) && foldersLocationRef.current === 0) {
				folderClipMenuOptions();
			} else if ((isFolderRef.current || isClipRef.current) && foldersLocationRef.current === 1) {
				archivedMenuOptions();
			} else if ((isFolderRef.current || isClipRef.current) && foldersLocationRef.current === 2) {
				recycledMenuOptions();
			} else if (isTreeViewRef.current) {
				treeViewMenuOptions();
			} else if (foldersLocationRef.current === 0) {
				defaultMenuOptions();
			}
		},
		[setAnchorPoint]
	);

  
	const handleClick = useCallback(() => (show ? setShow(false) : null), [show]);

	useEffect(() => {
		setCurrentFolder(props.currentFolder);
		setFoldersLocation(props.foldersLocation);

		if (usersRef.current.length === 0) {
			setUsers(props.users);
		}
		if (groupsRef.current.length === 0) {
			setGroups(props.groups);
		}

		document.addEventListener("click", handleClick);
		document.addEventListener("contextmenu", handleContextMenu);
		return () => {
			document.removeEventListener("click", handleClick);
			document.removeEventListener("contextmenu", handleContextMenu);
		};
	}, [props, show]);

	return (
		<>
			<Container>
				<Modal backdrop={modal.static ? "static" : true} show={showModal} onHide={modal.hideEvent ? modal.hideEvent : handleShowModal}>
					<Modal.Header>
						<Modal.Title>{modal.title}</Modal.Title>
					</Modal.Header>
					<Modal.Body>{modal.text}</Modal.Body>
					{modal.confirm || modal.cancel ?
						<Modal.Footer>
							{modal.confirm ?
								<Button variant="primary" onClick={modal.confirmHandler ? modal.confirmHandler : handleShowModal}>
									{modal.confirmButton}
								</Button>
								:
								null
							}
							{modal.cancel ?
								<Button variant="danger" onClick={handleShowModal}>
									{modal.cancelButton}
								</Button>
								:
								null
							}
						</Modal.Footer>
						:
						null
					}
				</Modal>

				{show ?
					(isFolder || isClip) && foldersLocation === 0 ?
						<ul className="menu" id="menu"
							style={{
								top: anchorPoint.y,
								left: anchorPoint.x
							}}
						>
							{/* <li className="menu-item" id="rename">
								<FontAwesomeIcon icon={faPenSquare} className="contextMenuIcons" />
								Rename
							</li>
							<li className="menu-item" id="groups">
								<FontAwesomeIcon icon={faUsers} className="contextMenuIcons" />
								Add to Group
							</li>
							<li className="menu-item" id="move">
								<FontAwesomeIcon icon={faFileExport} className="contextMenuIcons" />
								Move Item
							</li>
							<li className="menu-item" id="authlevel">
								<FontAwesomeIcon icon={faShieldAlt} className="contextMenuIcons" />
								Authorization Level
							</li>
							<li className="menu-item" id="transferowner">
								<FontAwesomeIcon icon={faExchangeAlt} className="contextMenuIcons" />
								Transfer Ownership
							</li>
							<li className="menu-item" id="lock">
								<FontAwesomeIcon icon={faLock} className="contextMenuIcons" />
								Toggle Lock
							</li> */}
							{/* <hr className="divider" /> */}
							<li className="menu-item" id="archive">
								<FontAwesomeIcon icon={faArchive} className="contextMenuIcons" />
								Archive
							</li>
							<li className="menu-item" id="recycle">
								<FontAwesomeIcon icon={faTrash} className="contextMenuIcons" />
								Recycle
							</li>
						</ul>
						:
						(isFolder || isClip) && foldersLocation === 1 ?
							<ul className="menu" id="menu"
								style={{
									top: anchorPoint.y,
									left: anchorPoint.x
								}}
							>
								<li className="menu-item" id="archive">
									<FontAwesomeIcon icon={faArchive} className="contextMenuIcons" />
									Undo Archive
								</li>
							</ul>
							:
							(isFolder || isClip) && foldersLocation === 2 ?
								<ul className="menu" id="menu"
									style={{
										top: anchorPoint.y,
										left: anchorPoint.x
									}}
								>
									<li className="menu-item" id="recycle">
										<FontAwesomeIcon icon={faTrash} className="contextMenuIcons" />
										Undo Recycle
									</li>
								</ul>
								:
								(isTreeView) ?
									<ul className="menu" id="menu"
										style={{
											top: anchorPoint.y,
											left: anchorPoint.x
										}}
									>
										<li className="menu-item" id="open">
											<FontAwesomeIcon icon={faFolderOpen} className="contextMenuIcons" />
											Open Folder
										</li>
										<li className="menu-item" id="create">
											<FontAwesomeIcon icon={faFolderPlus} className="contextMenuIcons" />
											New Folder
										</li>
									</ul>
									:
									foldersLocation === 0 ?
										<ul className="menu" id="menu"
											style={{
												top: anchorPoint.y,
												left: anchorPoint.x
											}}
										>
											<li className="menu-item" id="create">
												<FontAwesomeIcon icon={faFolderPlus} className="contextMenuIcons" />
												New Folder
											</li>
										</ul>
										:
										<></>
					:
					<></>
				}
			</Container>
		</>
	);
}

export default LibContextMenu;
