import { useState, useEffect } from 'react';

/* Debounce Hook is Handy for Manually Debouncing State Changes 
  i.e. Fetch, Search, etc. 
*/
export default function useDebounce(value, delay = 1000) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
        setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
}
