import React, { 
  useState, 
  useEffect
} from 'react'
import Loader from '../Loader'
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { useHistory } from 'react-router-dom';
import Image from 'react-bootstrap/Image';
import btnGoogle from '../../Assets/images/btn_google_normal.png';

const styles = {
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'table-cell'
  },
  font: {
    fontFamily: 'Roboto, Helvetica, sans-serif'
  },
  table: {
    maxHeight: '400px !important'
  },
  card: {
    header: {
      backgroundColor: '#727cf5',
      color: '#fff',
      fontWeight: 900
    }
  },
  icons: {
    save: {
      color: '#10b759',
      fontSize: '1.2rem',
      cursor: 'pointer',
      marginRight: '10px'
    },
    cancel: {
      color: '#fbbc06',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    edit: {
      color: '#727cf5',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    danger: {
      color: '#ff3366',
      fontSize: '1.2rem',
      cursor: 'pointer'
    }
  }
}

function GoogleSSO() {
    const [isLoading, setIsLoading] = useState(true);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState({
        title: '',
        text: ''
    });

    const handleShowAlertModal = () => setShowAlertModal(!showAlertModal);
    
	const enable = (e) => {
	   	e.preventDefault();
		window.location = `${window.apiSite}/google/create`
	} 

	const check = () => {
		fetch(`${window.apiSite}/google/check`, {
			method: 'POST',
      credentials: `include`,
			headers: {
				"Accept": "application/json"
			},
		})
		.then(async res => {
			let json = await res.json();
			let status = document.getElementById('googleStatus');
			if(json.err || !json.state) {
				status.outerHTML = '<p id=\"googleStatus\">Your Google Account is Not Connected</p>'
			} else {
				status.outerHTML = '<p id=\"googleStatus\" style=\"color:green !important;\">Your Google Account Is Connected</p>'
			}
		})
	}

    useEffect(() => {
		  check();
      setIsLoading(false);
    }, [])

    return isLoading ? (
        <Loader/>
    ) : (
        <React.Fragment>

            <Modal style={styles.font} show={showAlertModal} >
                <Modal.Header>
                <Modal.Title>{ alertModalContent.title } </Modal.Title>
                </Modal.Header>
                <Modal.Body>{ alertModalContent.text }</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleShowAlertModal}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Row className="mt-2">
                <Col>
                <CardDeck>
                    <Card>
                        <Card.Body>
                          <Form onSubmit={enable}>
                            <h3 id="ssoStatus">Enable Single Sign On with Google</h3>
                            <Button variant="primary" type="submit" style={{padding: "0px", border: "0px"}} className="bg-white"> 
                                <Image src={btnGoogle} fluid/>
                            </Button>
                          </Form>
						              <p id="googleStatus"></p>
                        </Card.Body>
                    </Card>
                </CardDeck>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default GoogleSSO;
