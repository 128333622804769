export const themeOptions = {
  palette: {
    primary: {
      main: '#1360EF',
    },
    secondary: {
      main: '#FFD700',
    },
    info: {
      main: '#1360EF',
    },
  },
}
