import { 
	FontAwesomeIcon 
} from '@fortawesome/react-fontawesome';
import { 
	faTimesCircle,
	faCheckCircle,
	faSpinner,
	faEyeSlash,
	faEye
} from '@fortawesome/free-solid-svg-icons';
import React, { 
	useState, 
	useEffect,
} from 'react';
import Loader from '../Loader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import ListGroup from 'react-bootstrap/ListGroup';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Alert from 'react-bootstrap/Alert';
import { sendData, onData, offData } from '../../Hooks/Socket';

const styles = {
	colors: {
		primary: {
			color: "#007bff"
		}
	},
	hidden: {
		display: 'none'
	},
	visible: {
		display: 'table-cell'
	},
	font: {
		fontFamily: 'Roboto, Helvetica, sans-serif'
	},
	table: {
		maxHeight: '400px !important'
	},
	card: {
		header: {
			backgroundColor: '#727cf5',
			color: '#fff'
		}
	},
	icons: {
		connected: {
			color: '#10b759',
		},
		disconnected: {
			color: '#ff3366'
		},
		save: {
			color: '#10b759',
			fontSize: '1.2rem',
			cursor: 'pointer',
			marginRight: '10px'
		},
		cancel: {
			color: '#fbbc06',
			fontSize: '1.2rem',
			cursor: 'pointer'
		},
		edit: {
			color: '#007bff',
			fontSize: '1.2rem',
			cursor: 'pointer'
		},
		danger: {
			color: '#ff3366',
			fontSize: '1.2rem',
			cursor: 'pointer'
		}
	},
	alignMiddle: {
		alignItems: "center"
	},
	spinner: {
		height: "5rem",
		width: "5rem"
	}
}

function AWSSetup(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isAWSIntegrated, setIsAWSIntegrated] = useState(false);
    const [requiredInfo, setRequiredInfo] = useState([]);
    const [isUpdatingAWS, setIsUpdatingAWS] = useState(false);
    const [awsRegion, setAWSRegion] = useState(null);
    const [awsAccessKeyId, setAWSAccessKeyId] = useState(null);
    const [awsSecretAccessKey, setAWSSecretAccessKey] = useState(null);
    const [awsInstanceId, setAWSInstanceId] = useState(null);
    const [connectEndpoint, setConnectEndpoint] = useState(null);
    const [showSecretAccessKey, setShowSecretAccessKey] = useState(false);
    const [showRequiredInfo, setShowRequiredInfo] = useState(true);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState({
        title: '',
        text: '',
        confirmButton: '',
        confirm: null,
        confirmHandler: null,
        cancelButton: '',
        cancel: null,
        static: false,
        hideEvent: null
    });

    const handleShowRequiredInfo = () => setShowRequiredInfo(!showRequiredInfo);

    const handleShowAlertModal = () => setShowAlertModal(!showAlertModal);

    const getAWSIntegration = () => {
		let data = {
			genUserId: localStorage.getItem('genUserId')
		}
		sendData('getAWSIntegration', data);
    }

    /**
     * Handles response to get
     * aws integration
     * @param {JSON} data response data
     *  @param {Boolean} data.integrated status on AWS integration
     *  @param {String} data.region AWS region
     *  @param {String} data.connectEndpoint AWS Connect endpoint
     */
    const onAWSIntegration = (data) => {
		if (data.integrated) {
			setIsAWSIntegrated(true);
			setAWSRegion(data.region);
			setConnectEndpoint(data.connectEndpoint);
		} else {
			setRequiredInfo(data.requiredInfo);
			setIsAWSIntegrated(false);
		}
		setIsLoading(false);
    }

    const updateAWSData = () => {
        if (!awsAccessKeyId || !awsSecretAccessKey || !awsRegion || !awsInstanceId) {
			setAlertModalContent({
				title: "Error",
				text: "All fields must be filled out!",
				confirm: false,
				cancel: true,
				cancelButton: "Close"
			});
			handleShowAlertModal();
        } else {
			let data = {
				genUserId: localStorage.getItem('genUserId'),
				username: awsAccessKeyId,
				password: awsSecretAccessKey,
				region: awsRegion,
				endpoint: awsInstanceId
			}
			sendData('updateAWSData', data);

			setAlertModalContent({
				title: "Verifying...",
				text: 
				<Row>
				<Col>
					<ListGroup variant="flush">
						<ListGroup.Item>
							<FontAwesomeIcon 
								size="lg"
								pull="left"
								style={styles.icons.disconnected}
								icon={faSpinner}  
								spin
							/>
							List Users Permission
						</ListGroup.Item>
						<ListGroup.Item>
							<FontAwesomeIcon 
								size="lg"
								pull="left"
								style={styles.icons.disconnected}
								icon={faSpinner}  
								spin
							/>
							Describe User Permission
						</ListGroup.Item>
					</ListGroup> 
				</Col>
				</Row>,
				confirm: false,
				cancel: false
			});
			handleShowAlertModal();
		}
    }

    /**
     * Handles response to updating
     * all AWS integration data
     * @param {JSON} data response data 
     */
    const onUpdateAWSData = (data) => {
		setAlertModalContent({
			title: "Verifying...",
			text: 
			<Row>
			<Col>
				<ListGroup variant="flush">
					<ListGroup.Item>
						<FontAwesomeIcon 
							size="lg"
							pull="left"
							style={data.permissions.listUsers ? styles.icons.connected : styles.icons.disconnected}
							icon={data.permissions.listUsers ? faCheckCircle : faTimesCircle}  
						/>
						List Users Permission
					</ListGroup.Item>
					<ListGroup.Item>
						<FontAwesomeIcon 
							size="lg"
							pull="left"
							style={data.permissions.describeUser ? styles.icons.connected : styles.icons.disconnected}
							icon={data.permissions.describeUser ? faCheckCircle : faTimesCircle}  
						/>
						Describe User Permission
					</ListGroup.Item>
				</ListGroup> 
				{ data.error ?
					<p>{data.message}</p>
					:
					null
				}
			</Col>
			</Row>,
			confirm: false,
			cancel: false
		});

		if (!data.validating) {
      setTimeout(()=> {
				setShowAlertModal(false);
        setIsUpdatingAWS(false);
				props.updIntegrations();
      }, 2000)
		}
    }

    const updateAWSAccess = () => {
        let data = {
			genUserId: localStorage.getItem('genUserId'),
			accessKeyId: awsAccessKeyId,
			secretAccessKey: awsSecretAccessKey
		}
        sendData('updateAWSAccess', data);
    }

    /**
     * Handles response to updating
     * AWS access keys
     * @param {JSON} data response data
     */
    const onUpdateAWSAccess = (data) => {
        setAlertModalContent({
			title: 'Success!', 
			text: 'You have successfully updated your AWS Access Keys',
			confirm: false,
			cancel: false,
			hideEvent: modalEventWrapper(props.updIntegrations)
        });
        handleShowAlertModal();
        
        setAWSAccessKeyId(null);
        setAWSSecretAccessKey(null);
    }

    const updateAWSRegion = () => {
        let data = {
            genUserId: localStorage.getItem('genUserId'),
            region: awsRegion
        }
        sendData('updateAWSRegion', data);
    }

    /**
     * Handles response to updating
     * the AWS region
     * @param {JSON} data response data
     */
    const onUpdateAWSRegion = (data) => {
        setAlertModalContent({
			title: 'Success!', 
			text: 'You have successfully updated your AWS Region',
			confirm: false,
			cancel: false,
			hideEvent: modalEventWrapper(props.updIntegrations)
        });
        handleShowAlertModal();

        setAWSRegion(null);
    }

    const updateAWSInstanceId = () => {
      let data = {
          genUserId: localStorage.getItem('genUserId'),
          instanceId: awsInstanceId
      }
      sendData('updateAWSInstanceId', data);
    }

    /**
     * Handles response to updating
     * AWS connect instance id
     * @param {JSON} data response data
     */
    const onUpdateAWSInstanceId = (data) => {
        setAlertModalContent({ 
			title: 'Success!', 
			text: 'You have successfully updated your AWS Connect Instance ID.',
			confirm: false,
			cancel: false,
			hideEvent: modalEventWrapper(props.updIntegrations)
        });
        handleShowAlertModal();

        setAWSInstanceId(null);
    }

    const startRemoveAWSIntegration = () => {
		setAlertModalContent({
			title: 'Remove AWS Integrations?',
			text: 'Are you sure you would like to remove your AWS integrations from Daily Stream? You may integrate again at any time.',
			confirm: true, 
			confirmButton: 'Confirm', 
			confirmHandler: removeAWSIntegration,
			cancel: true,
			cancelButton: 'Cancel',
		});
		handleShowAlertModal();
    }

    const removeAWSIntegration = () => {
		handleShowAlertModal();
		setAWSRegion(null);
		setAWSSecretAccessKey(null);
		setAWSAccessKeyId(null);

		let data = {
			genUserId: localStorage.getItem('genUserId')
		}
		sendData('removeAWSIntegration', data);
    }

    const onRemoveAWSIntegration = (data) => {
        setAlertModalContent({
          title: 'Success!', 
          text: 'You have successfully removed your AWS Integration.',
          confirm: false,
          cancel: false,
          hideEvent: modalEventWrapper(props.updIntegrations)
        });
        handleShowAlertModal();
    }

    const startImportAWSUsers = () => {
		setAlertModalContent({
				title: 'Import Users?',
				text: 'Are you sure you would like to import your users into Daily Stream?',
				confirm: true,
				confirmButton: 'Confirm',
				confirmHandler: importAWSUsers,
				cancel: true,
				static: false,
				cancelButton: 'Cancel'
		});
		handleShowAlertModal();
    }

    const importAWSUsers = () => {
		handleShowAlertModal();

		let data = {
			genUserId: localStorage.getItem('genUserId')
		}
		sendData('importAWSUsers', data);
    }

    const onImportAWSUsers = (data) => {
        setAlertModalContent({
			title: 'Success!',
			text: 'You have successfully imported your Amazon Connect users into Daily Stream.',
			confirm: false,
			cancel: true,
			cancelButton: 'Close'
        });
        handleShowAlertModal();
    }

    const modalEventWrapper = (cb) => {
		handleShowAlertModal();
		cb();
    }

    const copyText = (text) => {
		navigator.clipboard.writeText(text);
    }

    const renderTooltip = (params) => {
		return (
			<Tooltip id="default-tooltip">
			{params.message}
			</Tooltip>
		);
    }

    useEffect(() => {
		getAWSIntegration();

		onData('getAWSIntegration', onAWSIntegration);
		onData('updateAWSData', onUpdateAWSData);
		onData('updateAWSAccess', onUpdateAWSAccess);
		onData('updateAWSRegion', onUpdateAWSRegion);
		onData('updateAWSInstanceId', onUpdateAWSInstanceId);
		onData('removeAWSIntegration', onRemoveAWSIntegration);
		onData('importAWSUsers', onImportAWSUsers);

		return () => {
			offData('getAWSIntegration');
			offData('updateAWSData');
			offData('updateAWSAccess');
			offData('updateAWSRegion');
			offData('updateAWSInstanceId');
			offData('removeAWSIntegration');
			offData('importAWSUsers');
		}
    }, []);

    return isLoading ? (
        <Loader/>
    ) : ( 
        <React.Fragment>

        <Modal backdrop={alertModalContent.static ? "static" : true} show={showAlertModal} onHide={alertModalContent.hideEvent ? alertModalContent.hideEvent : handleShowAlertModal}>
            <Modal.Header>
            <Modal.Title>{alertModalContent.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{alertModalContent.text}</Modal.Body>
            { alertModalContent.confirm || alertModalContent.cancel ?
              <Modal.Footer>
              {alertModalContent.confirm ? 
                <Button variant="primary" onClick={() => alertModalContent.confirmHandler()}>
                    {alertModalContent.confirmButton}
                </Button>
              : 
                null
              }
              {alertModalContent.cancel ? 
                <Button variant="danger" onClick={handleShowAlertModal}>
                    {alertModalContent.cancelButton}
                </Button>
              : 
                null
              }
              </Modal.Footer>
            :
              null
            }
        </Modal>

        <Row className="mb-2" xl={1} lg={1}>
          <Col className="mb-2">
            { isUpdatingAWS ?
              <Card>
                <Card.Header>
                  Integration
                </Card.Header>
                <Card.Body>
                <Form>
                    <Form.Label>AWS Region</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control type="text" placeholder="us-west-2" value={awsRegion} onChange={(e) => setAWSRegion(e.target.value)} /> 
                    </InputGroup>
                    <Form.Label>AWS Access Key ID</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control type="text" placeholder="AKIAIOSFODNN7EXAMPLE" onChange={(e) => setAWSAccessKeyId(e.target.value)}/>
                    </InputGroup>
                    <Form.Label>AWS Secret Access Key</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control type={showSecretAccessKey ? "text" : "password"} placeholder="wJalrXUtnFEMI/K7MDENG/bPxRfiCYEXAMPLEKEY" onChange={(e) => setAWSSecretAccessKey(e.target.value)}/>
                      <InputGroup.Prepend>
                        <Button variant="outline-secondary" onClick={() => setShowSecretAccessKey(!showSecretAccessKey)} tabIndex="-1">
                        <FontAwesomeIcon 
                            size="lg"
                            icon={showSecretAccessKey ? faEye : faEyeSlash}  
                        />
                        </Button>
                      </InputGroup.Prepend>
                    </InputGroup>
                    <Form.Label>AWS Connect Instance ID</Form.Label>
                    <InputGroup className="mb-3">
                        <Form.Control type="text" placeholder="rh9ywapc-0pja-tq9n-ccbl-xipbtexample" onChange={(e) => setAWSInstanceId(e.target.value)}/>
                    </InputGroup>
                    <Button variant="primary" onClick={() => updateAWSData()} className="m-2">
                        Save
                    </Button>
                    {/* <Button variant="primary" onClick={() => updateAWSRegion()} className="mr-2">
                        Update AWS Region
                    </Button>
                    <Button variant="primary" onClick={() => updateAWSAccess()} className="mr-2">
                        Update Both Keys
                    </Button>
                    <Button variant="primary" onClick={() => updateAWSInstanceId()} className="mr-2">
                        Update Instance ID
                    </Button> */}
                    <Button variant="danger" onClick={() => setIsUpdatingAWS(false)} className="m-2">
                        Cancel
                    </Button>
                </Form>
                </Card.Body>
                </Card>
               : 
               <React.Fragment> 
                { isAWSIntegrated ? 
                <Card>
                    <Card.Header>
                    Integration
                    <FontAwesomeIcon 
                    size="lg"
                    pull="right"
                    style={styles.icons.connected}
                    icon={faCheckCircle}  
                    />
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                    Update Amazon Integration
                    </Card.Title>
                    <Card.Text>
                    You have successfully integrated with Amazon.
                    You may update your integration information below!
                    </Card.Text>
                    <Button variant="primary" size="small" className="m-2" onClick={() => setIsUpdatingAWS(true)}>Update</Button>
                    <Button variant="danger" size="small" className="m-2" onClick={() => startRemoveAWSIntegration()}>Remove Integration</Button>
                </Card.Body>
                </Card>
                : 
                <>
                { requiredInfo.length > 0 ? 
                  <Alert variant="danger" show={showRequiredInfo} onClose={handleShowRequiredInfo}>
                    <Alert.Heading>Required Info:</Alert.Heading>
                    <hr/>
                      { requiredInfo.includes('username') ? 
                        <h6>AWS Access Key</h6>
                        :
                        null
                      }
                      { requiredInfo.includes('password') ? 
                        <h6>AWS Secret Key</h6>
                        :
                        null
                      }
                      { requiredInfo.includes('aws_region') ? 
                        <h6>AWS Region</h6>
                        :
                        null
                      }
                      { requiredInfo.includes('endpoint') ?
                        <h6>AWS Connect Instance ID</h6>
                        :
                        null
                      }
                  </Alert>
                  :
                  null
                }
                <Card>
                <Card.Header>
                    Integration
                    <FontAwesomeIcon 
                    size="lg"
                    pull="right"
                    style={styles.icons.disconnected}
                    icon={faTimesCircle}  
                    />
                </Card.Header>
                <Card.Body>
                    <Card.Title>
                    Integrate with Amazon Today!
                    </Card.Title>
                    <Card.Text>
                    Our Daily Stream platform integrates with your Amazon Connect allowing
                    you to monitor your agent's activity in real time.
                    If you would like to begin integration, press the button below!
                    </Card.Text>
                    <Button variant="primary" size="small" onClick={() => setIsUpdatingAWS(true)}>Integrate</Button>
                    { requiredInfo.length < 4 ?
                      <Button variant="danger" size="small" onClick={() => startRemoveAWSIntegration()}>Remove Integration</Button>
                    :
                      null
                    }
                </Card.Body>
                </Card>
                </>
                }
                </React.Fragment>
            }
          </Col>
          { isAWSIntegrated ? 
          <Col className="mb-2">
            <Card>
              <Card.Header>
                Amazon Connect
              </Card.Header>
              <Card.Body>
                  <Card.Title>
                  Integrate with Amazon Connect
                  </Card.Title>
                  <Card.Text>
                  To begin, set up your AWS Kinesis data streams. Use the following HTTPS endpoint
                  when configuring your Kinesis data stream endpoint.
                  <InputGroup size="sm" className="mt-2 mb-2">
                    <FormControl type="text" value={connectEndpoint} readOnly/>
                    <OverlayTrigger
                      placement="top"
                      trigger="click"
                      delay={{ hide: 400 }}
                      overlay={renderTooltip({message: 'Copied!'})}
                    >
                    <Button variant="outline-secondary" onClick={() => copyText(connectEndpoint)}>Copy </Button>
                    </OverlayTrigger>
                  </InputGroup>
                  After you have configured your Kinesis streams and Connect Instance, you can import your agents directly into Daily Stream!
                  </Card.Text>
                  <Button size="small" variant="primary" onClick={() => startImportAWSUsers()}>Import Users</Button>
              </Card.Body>
            </Card>
          </Col>
          : null}
        </Row>
        </React.Fragment>
    );
}

export default AWSSetup;
    