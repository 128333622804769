import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Folder from '../Components/Folder'
import Viewer from '../Components/Viewer'
import LibContextMenu from '../Components/LibContextMenu'
import React, { useEffect } from 'react';
import useState from 'react-usestateref';
import Loader from '../Fragments/Loader'
import { sendData, offData, onData } from '../Hooks/Socket'
import {
	faFolder,
	faCaretDown,
	faCaretRight,
	faArchive,
	faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@mui/material/styles';
import { TreeView, TreeItem, treeItemClasses } from '@mui/x-tree-view';

function Library(props) {
	const [folders, setfolders] = useState(null)
	const [isLoading, setIsLoading] = useState(true)
	const [isUnavailable, setIsUnavailable] = useState(true)
	const [errorMessage, setErrorMessage] = useState(<Loader />)
	const [currentFolder, setCurrentFolder] = useState(null)
	const [foldersLocation, setFoldersLocation, foldersLocationRef] = useState(0);
	const [reloadPage, setReloadPage, reloadPageRef] = useState(true)
	const [foldersClips, setfoldersClips, foldersClipsRef] = useState({})
	const [expandedIds, setExpandedIds, expandedIdsRef] = useState([])
	const [groups, setGroups, groupsRef] = useState([])
	const [users, setUsers, usersRef] = useState([])

	const MinimizeIcon = () => {
		return (<FontAwesomeIcon icon={faCaretDown} className="treeViewIcons" />);
	}
	const MaximizeIcon = () => {
		return (<FontAwesomeIcon icon={faCaretRight} className="treeViewIcons" />);
	}

	const StyledTreeItem = styled((props) => (
		<TreeItem {...props} />
	))(() => ({
		[`& .${treeItemClasses.content}`]: {
			height: '32px',
			borderRadius: '8px',
			paddingTop: '4px',
		},
		[`& .${treeItemClasses.selected}`]: {
			backgroundColor: '#C5DBFC !important',
		},
		[`& .${treeItemClasses.label}`]: {
			marginLeft: '10px',
			fontSize: '16px !important',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
	}));

	const renderTreeView = (nodes) => (
		<StyledTreeItem key={nodes.id} id={nodes.id} nodeId={nodes.id} label={nodes.name}>
			{''}
			{Array.isArray(nodes.children)
				? nodes.children.map((node) => renderTreeView(node))
				: null}
		</StyledTreeItem>
	);

	const expandTreeView = (folderObject, id, newFolders) => {
		Object.keys(folderObject).forEach(key => {
			if (key == 'id' && folderObject[key] == id) {
				folderObject['children'] = [];

				for (let i = 0; i < newFolders.length; i++) {
					folderObject['children'].push({
						id: newFolders[i].id.toString(),
						name: newFolders[i].name,
						children: [],
					});
				}
			}
			if (typeof folderObject[key] === 'object' && folderObject[key] !== null) {
				expandTreeView(folderObject[key], id, newFolders)
			}
		});
	};

	useEffect(() => {
		onData('reloadFolders', () => {
			sendData('getFolders', {
				parentFolder: props.match.params.id,
				foldersToGet: 0,
			});
			setFoldersLocation(0);
			document.getElementById('active').style.backgroundColor = '#C5DBFC';
			document.getElementById('archived').style.backgroundColor = '#FFF';
			document.getElementById('recycled').style.backgroundColor = '#FFF';
		});
		onData('getFolders', async (data) => {
			if (data.error) {
				setErrorMessage(
					<Alert variant="danger">
						<Alert.Heading>No Library Available!</Alert.Heading>
						<p>
							Oh no! It looks like the library is unavailable.
							If you believe this is an error, please report
							this information to your Systems Administrator.
						</p>
					</Alert>
				)
				setIsUnavailable(true);
			} else {
				if (reloadPageRef.current === true) {
					setfoldersClips({});
					setExpandedIds([]);

					let foldersAndClips = {
						id: data.parentData[0].id.toString(),
						name: data.parentData[0].name,
						children: [],
					};

					if (foldersLocationRef.current === 0) {
						for (let i = 0; i < data.folders.length; i++) {
							foldersAndClips.children.push({
								id: data.folders[i].id.toString(),
								name: data.folders[i].name,
								children: [],
							});
						}
					}

					setCurrentFolder(data.homeFolder);
					setfoldersClips(foldersAndClips);
					setfolders(
						<><Folder folders={data.folders} files={data.files} name={data.parentData[0].name} currentFolder={data.homeFolder} /></>
					)
				} else {
					if (data.folders.length > 0) {
						expandTreeView(foldersClipsRef.current, data.folders[0].parentFolder, data.folders);
					}
				}

				setReloadPage(true);
				setIsLoading(false);
				setIsUnavailable(false);
			}
		});
		document.addEventListener('pointerdown', () => { Viewer.canplay() }, false);
		Viewer.unsetRoom();

		if (document.getElementById('active')) {
			setFoldersLocation(0);
			document.getElementById('active').style.backgroundColor = '#C5DBFC';
			document.getElementById('archived').style.backgroundColor = '#FFF';
			document.getElementById('recycled').style.backgroundColor = '#FFF';
		}

		if (usersRef.current.length === 0) {
			sendData('getUsers', {});
		}
		onData('getUsers', (data) => {
			setUsers(data);
		});

		if (groupsRef.current.length === 0) {
			sendData('getGroups', {});
		}
		onData('getGroups', (data) => {
			setGroups(data);
		});

		sendData('getFolders', {
			parentFolder: props.match.params.id,
			foldersToGet: 0,
		});

		return () => {
			offData('reloadFolders');
			offData('getFolders');
			document.removeEventListener('pointerdown', () => { Viewer.canplay() }, false);
		}
	}, [props.match.params.id])

	return isUnavailable ? (
		<>
			<Container>
				{errorMessage}
			</Container>
		</>
	) : (
		<>
			<Container fluid>
				<Row>
					<Col id="treeview" className='col-2' style={{ width: "18.0000%", flex: "0 0 18.000%", maxWidth: "18.000%" }}>
						{foldersClips?.children?.length > 0 &&
							<TreeView
								aria-label="rich object"
								defaultCollapseIcon={<MinimizeIcon />}
								defaultExpandIcon={<MaximizeIcon />}
								onNodeToggle={(event, nodeIds) => {
									if (!expandedIdsRef.current.includes(nodeIds[0])) {
										let newExpandedIds = expandedIds;
										newExpandedIds.push(nodeIds[0]);
										setExpandedIds(newExpandedIds);

										setReloadPage(false);
										sendData('getFolders', {
											parentFolder: parseInt(nodeIds[0]),
											foldersToGet: 0,
										});
									}
								}}
								sx={{ flexGrow: 1, maxWidth: '100%', overflowY: 'auto', fontWeight: '550' }}
							>
								{renderTreeView(foldersClips)}
							</TreeView>
						}
						<li className="folderDivider" />
						<li id='active' className="activeFolders" onClick={() => {
							sendData('getFolders', { parentFolder: currentFolder, foldersToGet: 0 });
							setFoldersLocation(0);
							document.getElementById('active').style.backgroundColor = '#C5DBFC';
							document.getElementById('archived').style.backgroundColor = '#FFF';
							document.getElementById('recycled').style.backgroundColor = '#FFF';
						}}>
							<FontAwesomeIcon icon={faFolder} className="menuIcons" />
							Active Folders
						</li>
						<li id='archived' className="menuOptions" onClick={() => {
							sendData('getFolders', { parentFolder: currentFolder, foldersToGet: 1 });
							setFoldersLocation(1);
							document.getElementById('active').style.backgroundColor = '#FFF';
							document.getElementById('archived').style.backgroundColor = '#C5DBFC';
							document.getElementById('recycled').style.backgroundColor = '#FFF';
						}}>
							<FontAwesomeIcon icon={faArchive} className="menuIcons" />
							Archived Folders
						</li>
						<li id='recycled' className="menuOptions" onClick={() => {
							sendData('getFolders', { parentFolder: currentFolder, foldersToGet: 2 });
							setFoldersLocation(2);
							document.getElementById('active').style.backgroundColor = '#FFF';
							document.getElementById('archived').style.backgroundColor = '#FFF';
							document.getElementById('recycled').style.backgroundColor = '#C5DBFC';
						}}>
							<FontAwesomeIcon icon={faTrash} className="menuIcons" />
							Recycled Folders
						</li>
					</Col>
					<Col className='col' style={{ width: "5.000%", flex: "0 0 5.000%", maxWidth: "5.000%" }}>
					</Col>
					<Col id="folders" className='col-9'>
						{isLoading ?
							<Loader />
							:
							<Row>
								{folders}
							</Row>
						}
					</Col>
				</Row>
			</Container>

			<LibContextMenu currentFolder={currentFolder} foldersLocation={foldersLocation} groups={groups} users={users} />
		</>
	);
}

export default Library
