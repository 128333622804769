const container = {
	width: '100%',
	minHeight: '580px',
	display: 'flex',
	overflow: 'auto',
	position: 'relative'
}

function Player() {
	return (
		<div id="videos" style={container}>
		<div id="videos1" style={{ minWidth: '960px' }}></div>
		<div id="videos2" style={{ minWidth: '960px' }}></div>
		<div id="videos3" style={{ minWidth: '960px' }}></div>
		</div>
	)
}

export default Player
