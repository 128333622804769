import React, { useEffect, useState, useMemo } from 'react';
import { sendData } from '../Hooks/Socket';
import Recorder from './Recorder';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import logger from './Logger';
const FILE_NAME = 'idle.JS';


function Idle() {
    const [idleTracking, setIdleTracking] = useState(parseInt(localStorage.getItem('idleTracking')));
    const [state, setState] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [idleTrackingStartTime, setIdleTrackingStartTime] = useState(null);
    const [modalContent, setModalContent] = useState({
		title: '',
		text: '',
		confirmButton: '',
		confirm: null,
		confirmHandler: null,
		cancelButton: '',
		cancel: null,
		static: false,
		hideEvent: null
	});

    const warnData = {
        userState: 1
    };

   

    const handleShowModal = () => setShowModal(!showModal);
    const checkRecordingStatus = () => {
        if(parseInt(localStorage.getItem('idleTracking')) && warnData.userState  && !Recorder.isStreaming){
            if(!warnData.idleTrackingStartTime){
                
                warnData.idleTrackingStartTime = new Date();
            }else{
                let currentTime = new Date();
                let timeDiff = Math.floor((currentTime.getTime() - warnData.idleTrackingStartTime.getTime()) / 1000);
                console.log(timeDiff);
                if(timeDiff >= 300){
                    // alert('You have been idle for 5 minutes. Please record your session.');
                    warnData.idleTrackingStartTime = null;
                    sendData('streamReminderReport', {event: 4})
                }

            }
           

         
        }
        
        warnData.timeout = setTimeout(() => {
            checkRecordingStatus();
        }, 30000);
    }

   

  //   const startIdleTimeoutModal = () => {
  //       var newDateTime = new Date(Recorder.idleTime).toLocaleTimeString();

	// 	setModalContent({
	// 		title: "Idle Timeout",
	// 		text: `You have been idle since ${newDateTime}. If you continue to be idle, you will be logged out in 5 minutes.`,
	// 		confirm: true,
	// 		confirmButton: "Ok",
	// 		confirmHandler: clearIdleTimeout,
	// 		cancel: false,
	// 		cancelButton: "Cancel"
	// 	});
	// 	handleShowModal();
	// }

    // const clearIdleTimeout = () => {
    //     logger('log', FILE_NAME, 89, `Clearing IdleTimeout State`, true, false);
    //     Recorder.isIdle = false;
    //     Recorder.idleTime = 0;
    //     // Recorder.isTimeoutModalVisible = false;
    //     // setShowModal(false);
    // }

	const startLockedScreenModal = () => {
		const newDateTime = new Date().toLocaleTimeString();
        sendData('streamReminderReport', {event: 6})
		setModalContent({
			title: "Locked Screen",
			text: `You locked your screen at ${newDateTime}. You cannot continue broadcasting while your screen is locked. Your broadcast has been stopped for you.`,
			confirm: true,
			confirmButton: "Ok",
			cancel: false,
		});
		handleShowModal();
	}

    const getIdlePerm = async () => {
        const permission = await window.IdleDetector.requestPermission();
    }

    const idleStateStatus = async () => {
        if (idleTracking) {
            Recorder.idleTrackingRequired = true;
        } else {
            Recorder.idleTrackingRequired = false;
        }

        if (idleTracking) {
            const status = await navigator.permissions.query({name: 'idle-detection'});

            if (status.state === 'granted') {
                setState(status.state);
                Recorder.idleTracking = true;
            } else {
                setState(status.state);
                Recorder.idleTracking = false;
            }

            status.addEventListener('change', () => {
                setState(status.state);
                if (status.state === 'granted') {
                    Recorder.idleTracking = true;
                } else {
                    Recorder.idleTracking = false;;
                }
            });
        } else {
            Recorder.idleTracking = false;
            Recorder.idleTrackingAuthLevel = null;
        }
    }

    const startIdleTracking = async () => {
        try{
            const status = await navigator.permissions.query({name: 'idle-detection'});

            if (status.state == 'granted') {
                Recorder.idleController = new AbortController();
                Recorder.idleDetector = new window.IdleDetector();
                // Recorder.startModal = startIdleTimeoutModal;
                // Recorder.clearModal = clearIdleTimeout;

                Recorder.idleDetector.addEventListener('change', () => {
                    console.log('Idle State Changed');
                    const userState = Recorder.idleDetector.userState;
                    const screenState = Recorder.idleDetector.screenState;

					if (screenState === 'locked') {
                        if (Recorder.isStreaming || Recorder.isClip) {
                            startLockedScreenModal();
                        }

						if (Recorder.isClip) {
							Recorder.stopClipping();
						} 
                        
                        if (Recorder.isStreaming) {
                            Recorder.stopStreaming();
                        }
					}
                    
                    Recorder.idleTimer = new Date().getTime();

                    if(userState == 'active') {
                        warnData.userState = 1
                        Recorder.isIdle = false;
                        Recorder.idleTime = 0;
                    }else{
                        warnData.userState = 0
                        // warnData.idleTrackingStartTime = null;
                        Recorder.isIdle = true;
                        Recorder.idleTime = new Date().getTime();
                    }
                    var timeOffset = ((new Date()).getTime() - Recorder.videoStartTime) / 1000;
                    
                    (timeOffset > 30) ? timeOffset = 0 : timeOffset = timeOffset;
                    
                    let idleData = {
                        genUserId: localStorage.getItem('genUserId'),
                        userId: localStorage.getItem('userId'),
                        state: userState,
                        timeOffset: timeOffset,
                        frame: Recorder.streamPosition
                    }
                    console.log("Is Streaming: " + Recorder.isStreaming);
                    console.log(idleData)
                    if(Recorder.isStreaming){
                        sendData('insertUserIdle', idleData);
                    }
                    
                });

                Recorder.idleDetector.start(
                    {
                        idle: 60000,
                        signal: Recorder.idleController.signal
                    }
                );
            } 
        } catch(err) {
            console.error(err);
        }
    }

    const stopIdleTracking = () => {
        if (Recorder.idleController) {
            Recorder.idleController.abort();
            Recorder.idleController = null;
            Recorder.idleDetector = null;
        }
    }
    

    useEffect(() => {
        checkRecordingStatus();
        idleStateStatus();
        startIdleTracking();
        return () => {
            clearTimeout(warnData.timeout);
        }
    }, []);

    return(
        <>
            <Modal background={modalContent.static ? "static" : "true"} show={showModal} onHide={modalContent.hideEvent ? modalContent.hideEvent : handleShowModal}>
                <Modal.Header>
                <Modal.Title>{modalContent.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalContent.text}</Modal.Body>
                { modalContent.confirm || modalContent.cancel ?
                    <Modal.Footer>
                    {modalContent.confirm ? 
                    <Button variant="primary" onClick={modalContent.confirmHandler ? modalContent.confirmHandler : handleShowModal}>
                        {modalContent.confirmButton}
                    </Button>
                    : 
                    null
                    }
                    {modalContent.cancel ? 
                    <Button variant="danger" onClick={handleShowModal}>
                        {modalContent.cancelButton}
                    </Button>
                    : 
                    null
                    }
                    </Modal.Footer>
                :
                    null
                }
            </Modal>

            <Container>
                <Row>
                <Col>
                { idleTracking ? 
                    <Alert variant="danger" show={state === "granted" ? false : true}> 
                        <Alert.Heading>Activity Permissions: </Alert.Heading>
                        { state === "prompt" ? 
                            <>
                            <p>
                                It looks like your company requires activity tracking; however,
                                we do not have permission to track user activity. Please give us permission
                                by clicking the button below.
                            </p>
                            <Button variant="secondary" size="sm" onClick={getIdlePerm}>Grant Permission</Button>
                            </>
                            :
                            <>
                            <p>
                                Oh no! Your companies requires activity tracking; however,
                                you have denied permission to track user activity. In order to give us
                                permission, you must reset your current activity permission. Please view the video
                                <span> <a href="https://cdn.dailystream.com/videos/reset_permissions.mp4" target="_blank">here</a> </span>
                                if you need help resetting your activity permission. After you have reset your permissions, please reload
                                Daily Stream.
                            </p>
                            </>
                        }
                    </Alert>
                    :
                    null
                } 
                </Col>
                </Row>
            </Container>
        </>
    );
}

export default Idle;
