function logger(type, file, line, message, log = true, clear = false){
    if(log){
        if(clear){
            console.clear()
        }
        console.info("+++++++++++++++++++++++")
        console.info(`File: ${file} - Line: ${line}`)
        console.info("-----------------------")
        if(type == 'error'){
            console.error(message);
            console.trace();
        }
    
        if(type == 'warn'){
            console.warn(message);
        }
    
        if(type == 'info'){
            console.info(message);
        }
    
        if(type == 'log'){
            console.log(message);
        }
    }
}

export default logger;