import React, {
    useEffect,
    useState
} from 'react';
import {
    sendData,
    onData,
	offData
} from '../Hooks/Socket';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import AWSSetup from '../Fragments/Admin/AWSSetup';
import Loader from '../Fragments/Loader';
import FiveNine from '../Fragments/Admin/FiveNine';

function CallCenterSetup() {
    const [isLoading, setIsLoading] = useState(true);
    const [integrated, setIntegrated] = useState(false);
    const [integration,setIntegration] = useState(null);

    const getIntegrations = () => {
        let data = {
            genUserId: localStorage.getItem('genUserId')
        }
        sendData('getIntegrations', data);
    }

    const onIntegrations = (data) => {
        if (data.integration == "aws") {
            setIntegrated(true);
            setIntegration("aws");
        } else if (data.integration == "f9") {
            setIntegrated(true);
            setIntegration("f9");
        } else {
            setIntegrated(false);
            setIntegration("");
        }
        setIsLoading(false);
    }
    
    useEffect(() => {

        getIntegrations();

        onData('getIntegrations', onIntegrations);

		return () => {
			offData('getIntegrations');
		}
    }, []);

    return isLoading ? (
        <Loader/>
    ) 
    : 
    (
        <React.Fragment>
            <Container>
                <Row>
                    { integrated ? 
                        integration === "aws"
                        ?
                        <AWSSetup updIntegrations={getIntegrations}/>
                        :
                        integration === "f9"
                        ?
                        <FiveNine updIntegrations={getIntegrations}/>
                        :
                        <></>
                    :
                        <Accordion>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0">
                                Amazon
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                <Card.Body><AWSSetup updIntegrations={getIntegrations}/></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="1">
                                Five9
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="1">
                                <Card.Body><FiveNine updIntegrations={getIntegrations}/></Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    }
                </Row>
            </Container>
        </React.Fragment>
    );
}

export default CallCenterSetup;
