import { 
    Row,
    Col,
    Card,
    Button,
    Form,
    FormControl
  } from 'react-bootstrap';
  
  import {
    Link
  } from 'react-router-dom'

  import Loader from '../Fragments/Loader';
  
  import { socket } from '../Hooks/Socket';
  
  
  import React, { 
    useState, 
    useEffect,
  } from 'react';
  

  
  
 
  
  function Search() {
    const [isLoading, setLoading] = useState(true);
    const [searchValues, setSearchValues] = useState([]);
    const [stateTable, setStateTable] = useState([]);

      function handleSubmit(e){
        e.preventDefault();
        socket.emit('getUserStateTablex', {
            genUserId: localStorage.getItem('genUserId')
          });

      }



      
   
  
  
      useEffect(() => {
    
          socket.emit('get_search_values', {
            genUserId: localStorage.getItem('genUserId')
          });
          
          socket.on('get_search_values', async (data) => {
            setSearchValues(data)
     
            setLoading(false);
          });

          socket.on('getUserStateTablex', async (table) => {
            let component = [];
            for (let item of table) {
       
              
              component.push(
                <tr><td>{new Date(item.duration * 1000).toISOString().substr(11, 8)}</td>
                <td>{(item.startFrame == -1) ? 'No Valid Clip' :
                    <Link to={`/user/2/02-09-2022/${item.startFrame}`} className="nav-link">
                        <button>Play Start</button>
                    </Link>}</td>
                <td>{(item.endFrame == -1) ? 'No Valid Clip' : 
                <Link to={`/user/2/02-09-2022/${item.endFrame}`} className="nav-link">
                    <button>Play End</button>
                    </Link>}</td>
                </tr>
              )
            }
            setStateTable(<>{component}</>)
          });
  

      }, []);
  
      return (isLoading ?
                <Loader/>
            :
          <React.Fragment>
             
          <Row className="mb-2">
            <Col>
             
  

                <Card>
                  <Card.Header>Update User</Card.Header>
                  <Card.Body>
                
                        <Form noValidate onSubmit={handleSubmit}>
                          <Form.Row>
                            <Form.Group as={Col} sm="12" md="6">
                              <Form.Label>User</Form.Label>
                              <Form.Control as="select" defaultValue="" size="sm">
                                <option value="">Search Users</option>
                                {
                                    searchValues[0].map((option, index) => {
                                        return (<option key={index} value={option.users}>{option.users}</option>)
                                    })
                                }
                              </Form.Control>
                            
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6">
                              <Form.Label>Status</Form.Label>
                              <Form.Control as="select" defaultValue="" size="sm">
                                <option value="">Search Status</option>
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                               
                              </Form.Control>
                             
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Phone"
                                name="phone"
                      
                              />
                             
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group as={Col} md="6">
                            <Form.Label>Department</Form.Label>
                              <Form.Control as="select" defaultValue="" size="sm">
                                <option value="">Search Department</option>
                                {
                                    searchValues[1].map((option, index) => {
                                        return (<option key={index} value={option.department}>{option.department}</option>)
                                    })
                                }
                              </Form.Control>
                             
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                            <Form.Label>State</Form.Label>
                              <Form.Control as="select" defaultValue="" size="sm">
                                <option value="">Search State</option>
                                {
                                    searchValues[2].map((option, index) => {
                                        return (<option key={index} value={option.state}>{option.state}</option>)
                                    })
                                }
                              </Form.Control>
                             
                            </Form.Group>
                          </Form.Row>
                          <Button type="submit">Search</Button>
                        </Form>
                     
                  </Card.Body>
                </Card>
        
  

            </Col>
          </Row>
          <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{width: '10%'}}>Duration</th>
                    <th style={{width: '10%'}}>Start frame</th>
                    <th style={{width: '60%'}}>End Frame</th>
                    {/* <th style={{width: '10%'}}>Action</th> */}
                  </tr>
                  </thead>
                  <tbody>
                      {stateTable}
                  </tbody>
                  </table>
      </React.Fragment>
      );
  }
  
  export default Search;
