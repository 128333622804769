import React, { 
	useState, 
	useEffect
} from 'react';
import Loader from '../Loader';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { 
	FontAwesomeIcon 
} from '@fortawesome/react-fontawesome';
import { 
	faTimesCircle,
	faRedo,
} from '@fortawesome/free-solid-svg-icons';
import {
  sendData,
  onData,
  offData
} from '../../Hooks/Socket';
import {
  DataGrid,
  GridCellModes,
  GridToolbar,
  GridActionsCellItem
} from "@mui/x-data-grid";

//Icon Styles
const styles = {
	colors: {
		primary: {
			color: "#007bff"
		}
	},
	hidden: {
		display: 'none'
	},
	visible: {
		display: 'table-cell'
	},
	font: {
		fontFamily: 'Roboto, Helvetica, sans-serif'
	},
	table: {
		maxHeight: '400px !important'
	},
	card: {
		header: {
			backgroundColor: '#727cf5',
			color: '#fff'
		}
	},
	icons: {
		connected: {
			color: '#10b759',
		},
		disconnected: {
			color: '#ff3366'
		},
		save: {
			color: '#10b759',
			cursor: 'pointer',
		},
		cancel: {
			color: '#fbbc06',
			cursor: 'pointer'
		},
		edit: {
			color: '#007bff',
			fontSize: '1.2rem',
			cursor: 'pointer'
		},
		danger: {
			color: '#ff3366',
			cursor: 'pointer'
		}
	},
	alignMiddle: {
		alignItems: "center"
	},
	spinner: {
		height: "5rem",
		width: "5rem"
	}
}

const formatDate = (d) => {
  if (d) {
    let date = new Date(d);
    let dateFormatted = date.toLocaleDateString();
    let timeFormatted = date.toLocaleTimeString();
    return `${dateFormatted} ${timeFormatted}`;
  } else {
    return '';
  }
}

function Groups() {
  const [isLoading, setIsLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({
    title: '',
    body: '',
    confirm: true,
    confirmText: 'Confirm',
    confirmHandler: () => {},
    cancel: true,
    cancelText: 'Cancel',
  });
  const [columns, setColumns] = useState([
    {
      headerName: 'Actions',
      field: 'actions',
      type: 'actions',
      width: 200,
      getActions: ({ row }) => {
        let actions = [];

        actions.push(
          <GridActionsCellItem
            icon={<i className="fa-solid fa-trash"></i>}
            label="Delete"
            title="Delete Group"
            onClick={() => startDeleteGroup(row.id)}
          />
        );

        if (row.active) {
          actions.push(
            <GridActionsCellItem
              icon={<FontAwesomeIcon style={styles.icons.danger} icon={faTimesCircle}/>}
              label="Deactivate"
              title="Deactivate Group"
              onClick={() => startDeactivation(row.id)}
            />
          );
        } else {
          actions.push(
            <GridActionsCellItem
              icon={<FontAwesomeIcon style={styles.icons.save} icon={faRedo}/>}
              label="Reactivate"
              title="Reactivate Group"
              onClick={() => startReactivation(row.id)}
            />
          );
        }
        
        return actions;
      }
    },
    {
      headerName: 'Group ID',
      field: 'id',
      type: 'number',
      width: 100,
    },
    { headerName: 'Company ID',
      field: 'company_id',
      type: 'number',
      width: 100,
    },
    {
      headerName: 'Name',
      field: 'name',
      type: 'string',
      width: 200,
    },
    {
      headerName: 'Created',
      field: 'created',
      type: 'string',
      width: 200,
      valueFormatter: (params) => formatDate(params.value),
    }
  ]);
  let groupName = '';

  const getGroups = () => {
    sendData('getGroups');
  }

  const onGetGroups = (groups) => {
    setGroups(groups);
    setIsLoading(false);
  }

  const startDeactivation = (id) => {
    setModalContent({
      title: 'Deactivate Group',
      body: 'Are you sure you want to deactivate this group?',
      confirm: true,
      confirmText: 'Confirm',
      confirmHandler: () => toggleGroup(id),
      cancel: true,
      cancelText: 'Cancel',
    });
    setShowModal(true);
  }

  const startReactivation = (groupId) => {
    setModalContent({
      title: 'Reactivate Group',
      body: 'Are you sure you want to reactivate this group?',
      confirm: true,
      confirmText: 'Confirm',
      confirmHandler: () => toggleGroup(groupId),
      cancel: true,
      cancelText: 'Cancel',
    });

    setShowModal(true);
  }

  const toggleGroup = (groupId) => {
    sendData('toggleGroup', { groupId: groupId });
  }

  const onToggleGroup = () => {
    setShowModal(false);

    getGroups();
  }

  const startDeleteGroup = (groupId) => {
    setModalContent({
      title: 'Delete Group',
      body: 'Are you sure you want to delete this group?',
      confirm: true,
      confirmText: 'Confirm',
      confirmHandler: () => deleteGroup(groupId),
      cancel: true,
      cancelText: 'Cancel'
    });

    setShowModal(true);
  }

  const deleteGroup = (groupId) => {
    sendData('deleteGroup', { groupId: groupId });
  }

  const onDeleteGroup = (data) => {
    setShowModal(false);
  
    setGroups(data);
  } 

  const startAddGroup = () => {
    setModalContent({
      title: 'Create Group',
      body: 
        <Form>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control 
            type="text" 
            onChange={(e) => groupName = e.target.value}>
          </Form.Control>
        </Form.Group>
        </Form>,
      confirm: true,
      confirmText: 'Confirm',
      confirmHandler: () => addGroup(),
      cancel: true,
      cancelText: 'Cancel'
    });

    setShowModal(true);
  }
  const addGroup = () => {
    sendData('addGroup', { groupName: groupName });
  }

  const onAddGroup = (data) => {
    setShowModal(false);

    groupName = '';
    setGroups(data);
  }

  useEffect(() => {
    getGroups();

    onData('getGroups', onGetGroups);
    onData('toggleGroup', onToggleGroup);
    onData('deleteGroup', onDeleteGroup);
    onData('addGroup', onAddGroup);

    return () => {
      offData('getGroups', onGetGroups);
      offData('toggleGroup', onToggleGroup);
      offData('deleteGroup', onDeleteGroup);
      offData('addGroup', onAddGroup);
    }
  }, []);

  return (
    <>
    <Modal style={styles.font} show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>{modalContent.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalContent.body}</Modal.Body>
      { modalContent.confirm || modalContent.cancel ? 
        <Modal.Footer>
          { modalContent.cancel &&
            <Button variant="danger" onClick={() => setShowModal(false)}>
              {modalContent.cancelText}
            </Button>
          }
          { modalContent.confirm &&
            <Button variant="primary" onClick={modalContent?.confirmHandler}>
              {modalContent.confirmText}
            </Button>
          }
        </Modal.Footer>
        : null
      }
    </Modal>

    <Card>
      <Card.Header>
        Manage Groups
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip placement="bottom" id="button-tooltip-1">Add Group</Tooltip>}
        >
          {({ ref, ...triggerHandler }) => (
            <Button
              variant="light"
              {...triggerHandler}
              ref={ref}
              onClick={() => startAddGroup()}
            >
              <i className="fa-solid fa-rectangle-history-circle-plus" style={styles.colors.primary}></i>
            </Button>
          )}
        </OverlayTrigger>
      </Card.Header>

      { isLoading ?
          <Loader/>
        :
          <Card.Body style={{ height: '500px'}}>
            <DataGrid
              columns={columns}
              rows={groups}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              components={{ Toolbar: GridToolbar }}
              componentsProps={{
                toolbar: {
                  csvOptions: { disableToolbarButton: true },
                  printOptions: { disableToolbarButton: true },
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 250 },
                },
              }}
              experimentalFeatures={{ newEditingApi: true }}
              disableSelectionOnClick
            />
        </Card.Body>
      }
    </Card>
    </>
  );
}

export default Groups;
