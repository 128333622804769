import { 
  Row,
  Col,
  Card,
  Button,
  Form,
  FormControl
} from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import {
  Link
} from 'react-router-dom'

import Loader from '../Fragments/Loader';

import { socket } from '../Hooks/Socket';


import React, { 
  useState, 
  useEffect,
} from 'react';
import { blue } from '@mui/material/colors';
import { faUnderline } from '@fortawesome/free-solid-svg-icons';
import { sendData, onData, offData } from '../Hooks/Socket';
const link = {
  color:"blue",
  backgroundColor:"white",
  textDecoration:"underline",
  cursor:"pointer"

}
const stateArray = [
  "Idle",
  "Active",
  "Start Broadcasting",
  "Stop Broadcasting",
  "Active Not Broadcasting"
]



function AdherenceReport(props) {
  const [tableBody, setTableBody] = useState([]);
  const [tableHead, setTableHead] = useState([]);
  const [agentId, setAgentId] = useState(null);
  const [reportDates, setreportDates] = useState(null)
  

  const loadUserData = () => {
    
    let tableHeadData = []
    
    tableHeadData.push(<tr><td>Name</td><td>Stream Active</td><td>Stream not Active</td></tr>);
      let tableData = []
      
      tableData.push(<tr><td>Steve</td><td>07:30</td><td>00:30</td><td>08:00</td></tr>);
    setTableHead(tableHeadData)
    setTableBody(tableData)
  }

  const ongetAdherenceDates = (data) => {
    setreportDates(data)
    loadDays(data)
  }
  
  const loadDays = (data = null) => {
    let reportData = reportDates ?? data;
    let tableHeadData = []
    tableHeadData.push(<tr key="x"><td>Select Report Date</td></tr>);
    let tableData = []
      
    for(let i =  0; i < reportData.length; i++){
      tableData.push(<tr key={`x-${i}`}><td onClick={() => {loadDay(reportData[i].dateTime.split("T")[0])}}>{new Date(reportData[i].dateTime.split("T")[0]).toDateString()}</td></tr>);

    }

    setTableHead(tableHeadData)
    setTableBody(tableData)
  }
  const ongetAdherenceReport = (data) => {
    console.log(data)
    let tableHeadData = []
    tableHeadData.push(<tr key="ux"><td>Event</td><td>Time</td><td>Total User Active</td></tr>);
    let tableData = []
      
    for(let i =  0; i < data.length; i++){
      tableData.push(<tr key={`ux-${i}`}><td>{stateArray[data[i].state]}</td><td>{new Date(data[i].startDateTime).toTimeString()}</td></tr>);

    }

    setTableHead(tableHeadData)
    setTableBody(tableData)
  }
  
  const loadDay = (data) =>{
    sendData('getAdherenceReport', {genUserId: localStorage.getItem('genUserId'), userId: props.match.params.agentid, dateTime: data})
  }


    useEffect(() => {
      setAgentId(props.match.params.agentid);
      sendData('getAdherenceDates', {genUserId: localStorage.getItem('genUserId'), userId: props.match.params.agentid})
      onData('getAdherenceDates', ongetAdherenceDates);
      onData('getAdherenceReport', ongetAdherenceReport);
      return () => { 
        offData('getAdherenceDates');
        offData('getAdherenceReport');
      }
    }, []);


    return (
        <React.Fragment>

     
  
        <table className="table">
        <thead>
         
            {tableHead}
           
        
          </thead>
          <tbody>
              {tableBody}
          </tbody>
          </table>
    </React.Fragment>
    );
}

export default AdherenceReport;
