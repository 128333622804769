import React from 'react'
import { MentionsInput, Mention } from 'react-mentions'
import { shareClipMentions } from './higher-order'
import defaultStyle from './defaultStyle'
import defaultMentionStyle from './defaultMentionStyle'
  
function ShareClipMentions({ value, data, onChange, onAdd, onRemove }) {
	return (
		<div className="multiple-triggers">

		<MentionsInput
			value={value}
			onChange={onChange}
			style={defaultStyle}
			placeholder={"Share with people using '@'"}
			a11ySuggestionsListLabel={"Suggested mentions"}
		>
			<Mention
			markup="@[__display__]{__id__}"
			trigger="@"
			data={data}
			renderSuggestion={(
				suggestion,
				search,
				highlightedDisplay,
				index,
				focused
			) => (
				<div className={`user ${focused ? 'focused' : ''}`}>
				{highlightedDisplay}
				</div>
			)}
			onAdd={onAdd}
      onRemove={onRemove}
			style={defaultMentionStyle}
			/>
		</MentionsInput>
		</div>
	)
}

const asExample = shareClipMentions(
  ""
)
  
export default asExample(ShareClipMentions)
