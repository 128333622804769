//Imports
import { 
  FontAwesomeIcon 
} from '@fortawesome/react-fontawesome';
import { 
  faEyeSlash,
  faEye
} from '@fortawesome/free-solid-svg-icons';
import { sendData, onData, offData } from '../../Hooks/Socket'
import PasswordChecklist from "react-password-checklist"
import React, { 
  useState, 
  useEffect
} from 'react'
import Loader from '../Loader'
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

//Icon Styles
const styles = {
  hidden: {
    display: 'none'
  },
  visible: {
    display: 'table-cell'
  },
  font: {
    fontFamily: 'Roboto, Helvetica, sans-serif'
  },
  table: {
    maxHeight: '400px !important'
  },
  card: {
    header: {
      backgroundColor: '#727cf5',
      color: '#fff',
      fontWeight: 900
    }
  },
  icons: {
    save: {
      color: '#10b759',
      fontSize: '1.2rem',
      cursor: 'pointer',
      marginRight: '10px'
    },
    cancel: {
      color: '#fbbc06',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    edit: {
      color: '#727cf5',
      fontSize: '1.2rem',
      cursor: 'pointer'
    },
    danger: {
      color: '#ff3366',
      fontSize: '1.2rem',
      cursor: 'pointer'
    }
  }
}

/**
 * Daily Stream user settings page 
 */
function UserSettings() {
    //Component States
    const [isLoading, setIsLoading] = useState(true);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("")
    const [PasswordStrength, setPasswordStrength] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const [checkBoxCheck, setCheckBoxCheck] = useState(true);
    const [phone, setPhone] = useState(null);
    const [alertModalContent, setAlertModalContent] = useState({
        title: '',
        text: ''
    });

	//Add in an auto mute checkbox

    //Modal helper
    /**
     * Handles opening and closing
     * of modal window 
     */
    const handleShowAlertModal = () => setShowAlertModal(!showAlertModal);
    
    /**
     * Handles toggling the password mask 
     */
    const updateMask = (event) => {
        if (checkBoxCheck) {
            document.getElementById('old-password').type= 'text';
            document.getElementById('password').type = 'text';
            document.getElementById('password2').type = 'text';
            setCheckBoxCheck(false);
        } else {
            document.getElementById('old-password').type= 'password';
            document.getElementById('password').type = 'password';
            document.getElementById('password2').type = 'password';
            setCheckBoxCheck(true);
        }
    }

    /**
     * Sends request data to
     * update a user's password
     */
    const updatePassword = (e) => {
        e.preventDefault();
        if (!PasswordStrength) {
            setAlertModalContent({
                title: 'Error',
                text: 'Password must meet the following requirements: 8 characters with at least 1 upper case, 1 lower case, 1 number, and 1 special character'
            });
            handleShowAlertModal();
        } else {
            let data = {
                genUserId: localStorage.getItem('genUserId'),
                userId: localStorage.getItem('userId'),
                oldPassword: oldPassword,
                password: password,
                confirmPassword: passwordAgain
            }
            sendData('updatePassword', data);
        }
        document.getElementById('old-password').value = "";
        document.getElementById('password').value = "";
        document.getElementById('password2').value = "";
    }

    /**
     * Handles response to updating
     * user's password
     * @param {JSON} data response data 
     */
    const onUpdatePassword = (data) => {
    
        if (data.updated) {
            //Show success message
            setAlertModalContent({
                title: 'Success',
                text: 'Password has been updated!'
            });
            handleShowAlertModal();

            //Clear password reset data
            setOldPassword("");
            setPassword("");
            setPasswordAgain("");
        } else {
            //Show error message
            setAlertModalContent({
                title: 'Error',
                text: data.error
            });
            handleShowAlertModal();
        }
    }

    /**
     * Sends request to update 
     * user's phone number 
     */
    const updatePhone = (e) => {
        e.preventDefault();
        if (phone.length != 10) {
            setAlertModalContent({
                title: 'Error',
                text: 'Phone number must be 10 digits'
            });
            handleShowAlertModal();
        }else{
            let data = {
                genUserId: localStorage.getItem('genUserId'), 
                userId: localStorage.getItem('userId'), 
                phone: phone
            }
            sendData('updatePhone', data);
        }
        document.getElementById('phone').value = "";
    }

    /**
     * Handles response to updating
     * user's phone number
     * @param {JSON} data response data 
     */
    const onUpdatePhone = (data) => {
        //Show success message
        setAlertModalContent({
            title: 'Success',
            text: 'Phone has been updated!'
        });
        handleShowAlertModal();

        //Clear phone data
        setPhone("");
    }
    
    /**
     * Executes after component
     * has rendered
     */
    useEffect(() => {
        //Socket listeners
        onData('updatePassword', onUpdatePassword);
        onData('updatePhone', onUpdatePhone);

        //Set component loading state
        setIsLoading(false);

		return () => {
			offData('updatePassword');
			offData('updatePhone');
		}
    }, [])

    return isLoading ? (
        <Loader/>
    ) : (
        <React.Fragment>

            <Modal style={styles.font} show={showAlertModal} >
                <Modal.Header>
                <Modal.Title>{ alertModalContent.title } </Modal.Title>
                </Modal.Header>
                <Modal.Body>{ alertModalContent.text }</Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={handleShowAlertModal}>
                    Close
                </Button>
                </Modal.Footer>
            </Modal>

            <Row className="mt-2">
                <Col>
                <CardDeck>
                    <Card className="mb-2">
                        <Card.Header>Password</Card.Header>
                        <Card.Body>
                        <Form onSubmit={updatePassword}> 
                            <Form.Label>Old Password</Form.Label>
                            <InputGroup size="sm" className="mb-3">
                            <FormControl id="old-password" type={showPassword ? "text" : "password"} placeholder="Old Password" value={oldPassword} onChange={e => setOldPassword(e.target.value)}/>
                            <InputGroup.Prepend>
                                <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                                <FontAwesomeIcon 
                                    size="lg"
                                    icon={showPassword ? faEye : faEyeSlash}  
                                />
                                </Button>
                            </InputGroup.Prepend>
                            </InputGroup>
                            <Form.Label>New Password</Form.Label>
                            <InputGroup size="sm" className="mb-3">
                            <FormControl id="password" type={showPassword ? "text" : "password"} placeholder="New Password" value={password} onChange={e => setPassword(e.target.value)}/>
                            <InputGroup.Prepend>
                                <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                                <FontAwesomeIcon 
                                    size="lg"
                                    icon={showPassword ? faEye : faEyeSlash}  
                                />
                                </Button>
                            </InputGroup.Prepend>
                            </InputGroup>
                            <Form.Label>Confirm New Password</Form.Label>
                            <InputGroup size="sm" className="mb-3">
                            <FormControl id="password2" type={showPassword ? "text" : "password"} placeholder="Confirm New Password" value={passwordAgain} onChange={e => setPasswordAgain(e.target.value)}/>
                            <InputGroup.Prepend>
                                <Button variant="outline-secondary" onClick={() => setShowPassword(!showPassword)} tabIndex="-1">
                                <FontAwesomeIcon 
                                    size="lg"
                                    icon={showPassword ? faEye : faEyeSlash}  
                                />
                                </Button>
                            </InputGroup.Prepend>
                            </InputGroup>
                            <PasswordChecklist
                                rules={["minLength","specialChar","number","capital","match"]}
                                minLength={8}
                                value={password}
                                valueAgain={passwordAgain}
                                onChange={(isValid) => {setPasswordStrength(isValid)}}
                                className="mb-3"
                            />
                            <Button variant="primary" type="submit" className="mt-3"> 
                                Update Password
                            </Button>
                        </Form>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header>Phone Number</Card.Header>
                        <Card.Body>
                            <Form onSubmit={updatePhone}>
                                <Form.Label>Phone Number</Form.Label>
                                <InputGroup size="sm" className="mb-3">
                                <FormControl type="tel" id="phone" placeholder="Change Phone Number" onChange={(e) => setPhone(e.target.value)} required/>
                                </InputGroup>
                                <Button variant="primary" type="submit">
                                    Update Phone Number
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </CardDeck>
                </Col>
            </Row>
        </React.Fragment>
    );
}

//Exports
export default UserSettings;
