import React from 'react'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import Viewer from './Viewer'
import { useHistory } from "react-router-dom"

function Carousel(props) {
 
	const history = useHistory()
	const responsive = {
		0: { items: 1 },
		568: { items: 2 },
		1024: { items: 3 },
	}

	const cursor = {
		cursor: 'pointer'
	}

	var items = []
	var colorNum = 0;
	for (let data of props.metaData) {
	
		let newstyle = {
			width:"239px",
			height: "118px"
		}
		var colorSelection
		if (colorNum%2 == 0) {
			colorSelection = '#09f'
		} else {
			colorSelection = '#24a8be '
		}

		const imageBox = {
			cursor: 'pointer',
			width: '239px',
			height: '180px', 
			backgroundColor: colorSelection,
			textAlign: 'center',
			backgroundImage: 'url("https://cdn.dailystream.com/images/video.png")',
			backgroundSize: "cover",
			paddingTop: "1em"
		}
		
		const activePercentCSS = {
			marginTop: '40%'
		}

		if (data.isUser) {
		let url = `https://via.placeholder.com/200x118.png${colorSelection}?text=Date+${data.name}`
		items.push(
			<div  onClick={() => { Viewer.setCustomDay(data.datestamp); Viewer.start(data.maxID) }} > 
			<div style={imageBox}>{data.date}</div>
			
			{/* <img style={cursor} src={url} /> */}
			</div>
		)

		
		} else {
		let urlClick = `/user/${data.userId}/${data.date}/0`;
		
		let url = `https://via.placeholder.com/200x118.png${colorSelection}?text=Date+${data.name}`

		items.push(
			<div onClick={() => { Viewer.setCustomDay(data.date); history.push(urlClick) }} > 
			<div style={imageBox}>{data.date}
		
			<div style={activePercentCSS}>{data.idlePercent} Active</div>
			</div>
			
			{/* <img style={cursor} src={url} /> */}
			</div>
		)
		}

		colorNum++;
	}
    
	return (
		<AliceCarousel
			mouseTracking
			items={items}
			responsive={responsive}
			disableDotsControls
		/>
	);
}

export default Carousel;
