import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
	faStepBackward, 
	faUndo, 
	faPlayCircle, 
	faPauseCircle, 
	faRedo, 
	faStepForward, 
	faInfoCircle 
} from '@fortawesome/free-solid-svg-icons';
import Viewer from '../Components/Viewer';
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const customStyles = {
	content : {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)'
	},
	overlay: { zIndex: 1000 }
}

const attributes = {
	className: 'custom-root'
}
const widthStyle100 = {
	width: '100%'
}
const floatRight = {
	float: "right"
}
const cursorMouse = {
	cursor: "pointer"
}
const muter = {
	display: 'flex',
	alignItems: 'center',
	cursor: "pointer"
}

function StreamControls(props) {
	const renderTooltip = (params) => {
		return (
			<Tooltip id="button_tooltip"> 
				{params.message}
			</Tooltip>
		);
	}

	const setUserInteraction = (e) => {
		Viewer.userInteraction = true;
	}

	const scrubberUpdateViewer = (e) => {
		Viewer.updateTime(e.target.value);
	}

	const scrubberUpdateFrame = (e) => {
		if (e.button == 0) {
			let position = document.getElementById('position').value;
			Viewer.userInteraction = false;
			Viewer.scrubberUpdateFrame(position);
		}
	}
	
	const volumeUpdate = () => {
		let volume = document.getElementById('volume').value;
		let mute = document.getElementById('mute');

		volume = volume / 100;

		Viewer.isMuted = false;
		Viewer.volume = volume;

		mute.innerHTML = '🔊';
		Viewer.setAllFrameVolume(volume);
	}

	const updateScrubberDuration = (duration) => {
		document.getElementById("position").max = duration;
	}

	useEffect(() => {
		let genUserId = localStorage.getItem('genUserId');

		Viewer.setRoom(props.room, props.customDay, genUserId, false);

		Viewer.setScrubberDuration = updateScrubberDuration;
	}, []);

	return (
		<div id="controls" style={{ marginTop: '15px', width: '100%' }}>
			<input
			id="position" 
			type="range" 
			min="0.0" 
			max="100.0" 
			defaultValue="0.0" 
			step="0.0333" 
			style={widthStyle100} 
			onMouseDown={setUserInteraction}
			onMouseUp={scrubberUpdateFrame} 
			onChange={scrubberUpdateViewer}
			/>
		<div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Play Stream from Beginning"})}
			>
				<Button 
					id="restart" 
					variant="outline-primary"
					onClick={Viewer.restartFrame} 
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faStepBackward} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Jump Back 10 Seconds"})}
			>
				<Button 
					id="jbackButton" 
					variant="outline-primary"
					onClick={Viewer.jumpBackFrame}
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faUndo} /> 
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Play Stream"})}
			>
				<Button 
					id="play" 
					variant="outline-primary"
					onClick={Viewer.resumeFrame} 
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faPlayCircle} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Pause Stream"})}
			>
				<Button 
					id="pause" 
					variant="outline-primary"
					onClick={Viewer.pauseFrame} 
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faPauseCircle} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Jump Forward 10 Seconds"})}
			>
				<Button 
					id="jforwardButton" 
					variant="outline-primary"
					onClick={Viewer.jumpForwardFrame}
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faRedo} />
				</Button>
			</OverlayTrigger>
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "Play Stream from Live"})}
			>
				<Button 
					id="live" 
					variant="outline-primary"
					onClick={Viewer.liveFrame} 
					style={{marginRight: '5px'}}
				>
					<FontAwesomeIcon icon={faStepForward} />
				</Button>
			</OverlayTrigger>
			<span 
				className="ml-4" 
				id="mute" 
				style={muter} 
				onClick={Viewer.mute}
			>{Viewer.isMuted ? '🔇' : '🔊'}</span>
			<input 
				id="volume"  
				type="range" 
				min="0" 
				max="100" 
				defaultValue={Viewer.isMuted ? 0 : Viewer.volume * 100}
				step="1" 
				onChange={volumeUpdate}
			/>
		</div>
		<div id="index" style={floatRight}>
		{/* <span id="current"></span>/<span id="files"></span> */}
			<span id="timeIndex"></span> 
			<OverlayTrigger
				placement="top"
				delay={{ show: 150, hide: 150 }}
				overlay={renderTooltip({message: "/"})}
			>
				<span>
					<FontAwesomeIcon style={{marginLeft: '2px'}} icon={faInfoCircle}/>
				</span>
			</OverlayTrigger>
		</div>
		</div>    
	);
}

export default StreamControls;
