import { 
	FontAwesomeIcon 
} from '@fortawesome/react-fontawesome';
import { 
  faInfoCircle,
  faCheck,
  faTimesCircle
} from '@fortawesome/free-solid-svg-icons';
import React, { 
	useState, 
	useEffect,
} from 'react';
import Loader from '../Loader';
import { sendData, onData, offData } from '../../Hooks/Socket';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const styles = {
	colors: {
		primary: {
			color: "#007bff"
		}
	},
	hidden: {
		display: 'none'
	},
	visible: {
		display: 'table-cell'
	},
	font: {
		fontFamily: 'Roboto, Helvetica, sans-serif'
	},
	table: {
		maxHeight: '400px !important'
	},
	card: {
		header: {
			backgroundColor: '#727cf5',
			color: '#fff'
		}
	},
	icons: {
		connected: {
			color: '#10b759',
		},
		disconnected: {
			color: '#ff3366'
		},
		updating: {
			color: '#0d6efd'
		},
		info: {
			color: '#808080'
		}
	},
	alignMiddle: {
		alignItems: "center"
	},
	spinner: {
		height: "5rem",
		width: "5rem"
	}
}

function SystemSettings() {
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingDomains, setIsLoadingDomains] = useState(true);
    const [systemSettings, setSystemSettings] = useState(null);
    const [domains, setDomains] = useState([]);
    const [newDomain, setNewDomain] = useState('')
    const [hasSaved, setHasSaved] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [alertModalContent, setAlertModalContent] = useState({
      title: '',
      text: '',
      confirmButton: '',
      confirm: null,
      confirmHandler: null,
      cancelButton: '',
      cancel: null,
      static: false
    });
    const [confirmModalContent, setConfirmModalContent] = useState({
      title: '',
      text: '',
    })
    const maxIdleTrackingMessage = `Max idle tracking auth sets the highest 
                                    authentication level that Daily Stream idle 
                                    tracking will monitor. For example, a max 
                                    idle tracking auth of 6 will report idle 
                                    information on users with an auth level 
                                    of 6 or less.`;
    const maxWarnExtensionMessage = `Max extension warning auth sets the highest 
                                    authentication level that the Daily Stream 
                                    extension remind to record when active. 
                                    For example, a max extension warning auth 
                                    of 6 will remind users with an auth level 
                                    of 6 or less.`;
    const manageDomainsMessage = `This section determines what domains people
                                 can use Single Sign-In with.
                                 For example, if you have only added "example.com",
                                 people can only use Single Sign-In with emails
                                 that end with "example.com". You may add as many
                                 domains as you want.`

    const domainStyle = `
      Row.alt Col:nth-child(2n+2): {
        background:#ccc;
      }
    `

    const savedChanges = () => {
		setHasSaved(true);
		setTimeout(() => {
			setHasSaved(false);
		}, 5000);
    }

    const getSystemSettings = async () => {
		let data = {
			genUserId: localStorage.getItem('genUserId')
		}
		sendData('getSystemSettings', data);
    }

    /**
     * Handles response to request
     * for current system settings
     * @param {JSON} data response data 
     */
    const onGetSystemSettings = async (data) => {
		  setSystemSettings({
        trackIdle: data.trackIdle,
        maxIdleTrackAuth: data.maxIdleTrackAuth,
        warnExtension: data.warnExtension,
        maxWarnExtensionAuth: data.maxWarnExtensionAuth,
        trackWebcam: data.trackWebcam
		  });
		  setIsLoading(false);
    }

    const onGetSsoDomains = async (data) => {
      if(!data.error) {
        setDomains(data)
        setIsLoadingDomains(false);
      } else {
        setIsLoadingDomains(true);
      }
    }

    const insSsoDomain = async () => {
      const formattedNewDomain = newDomain.trim().toLocaleLowerCase()
      if(!newDomain.length) {
        setConfirmModalContent({
		  	  title: 'No domain entered',
		  	  text: `You must type in a valid domain before submitting.`,
		    });
		    setShowConfirmModal(true);
      } else if (!/^[a-z0-9\-]+\.[a-z0-9\-]+$/.test(formattedNewDomain)) {
        setConfirmModalContent({
		  	  title: 'Invalid domain entered',
		  	  text: `Please ensure your domain meets our Domain Requirements. 
                If you have further difficulties, please contact us.`,
		    });
		    setShowConfirmModal(true);
      } else {
        sendData(`insSsoDomain`, {domain: formattedNewDomain})
        setNewDomain('');
      }
    }

    const onInsSsoDomain = async (data) => {
      if(data.error) {
        setConfirmModalContent({
          title: `Error`,
          text: `This domain has already been claimed.`
        })
      } else {
        setConfirmModalContent({
          title: `Success`,
          text: `Your domain has been successfully claimed.`
        })
      }
      sendData(`getSsoDomains`)
      setShowConfirmModal(true)
    }

    const startDelSsoDomain = async (domainId) => {
      const index = domains.findIndex((d) => d.id === domainId)

      setAlertModalContent({
		  	title: 'Delete Authorized Domain?',
		  	text: `Are you sure you would like to delete the domain "${domains[index].domain}"?`,
		  	confirm: true,
		  	confirmButton: 'Confirm',
		  	confirmHandler: () => delSsoDomain(domainId),
		  	cancel: true,
		  	cancelButton: 'Cancel',
		  	static: false
		  });
		  setShowAlertModal(true);
    }

    const delSsoDomain = async (domainId) => {
      setShowAlertModal(false);
      sendData(`delSsoDomain`, {domainId})
    }

    const onDelSsoDomain = async () => {
      sendData(`getSsoDomains`)
    }
    
    const updateIdleTracking = async (trackIdle) => {
		let data = {
			genUserId: localStorage.getItem('genUserId'),
			idleTracking: trackIdle
		}
		sendData('setIdleTracking', data);
    } 

    /**
     * Handles response to request
     * to update idle tracking setting
     * @param {JSON} data response data
     */
    const onUpdateIdleTracking = async (data) => {
		getSystemSettings();
		savedChanges();
    }

    const updateIdleTrackingAuth = async (idleTrackingAuth) => {
		let data = {
			genUserId: localStorage.getItem('genUserId'),
			idleTrackingAuth: parseInt(idleTrackingAuth)
		}
		sendData('setIdleTrackingAuth', data);
    }

    /**
     * Handles response to request to update
     * the idle tracking auth level
     * @param {JSON} data response data 
     */
    const onUpdateIdleTrackingAuth = async (data) => {
      getSystemSettings();
      savedChanges();
    }

    const updateWarnExtension = async (warnExtension) => {
		  let data = {
		  	genUserId: localStorage.getItem('genUserId'),
		  	warnExtension,
		  }
		  sendData('setWarnExtension', data);
    } 

    const onUpdateWarnExtension = async (data) => {
		  getSystemSettings();
		  savedChanges();
    }

    const updateWarnExtensionAuth = async (warnExtensionAuth) => {
		  let data = {
		  	genUserId: localStorage.getItem('genUserId'),
		  	warnExtensionAuth: parseInt(warnExtensionAuth)
		  }
		  sendData('setWarnExtensionAuth', data);
    }

    const onUpdateWarnExtensionAuth = async (data) => {
      getSystemSettings();
      savedChanges();
    }

    const updateTrackingWebcam = async (trackWebcam) => {
		let data = {
			genUserId: localStorage.getItem('genUserId'),
			webcamTracking: trackWebcam
		}
		sendData('setWebcamTracking', data);
    }

    /**
     * Handles response to request to update 
     * the webcam tracking
     * @param {JSON} data response data 
     */
    const onUpdateTrackingWebcam = async (data) => {
		getSystemSettings();
		savedChanges();
    }

    const renderTooltip = (props) => {
		return (
			<Tooltip id="default-tooltip">
			{props.message}
			</Tooltip>
		);
    }

    useEffect(() => {
		getSystemSettings();
    sendData(`getSsoDomains`, null)

    onData('getSystemSettings', onGetSystemSettings);
    onData('setIdleTracking', onUpdateIdleTracking);
    onData('setIdleTrackingAuth', onUpdateIdleTrackingAuth);
    onData('setWarnExtension', onUpdateWarnExtension);
    onData('setWarnExtensionAuth', onUpdateWarnExtensionAuth);
    onData('setWebcamTracking', onUpdateTrackingWebcam);
    onData(`getSsoDomains`, onGetSsoDomains)
    onData(`insSsoDomain`, onInsSsoDomain)
    onData(`delSsoDomain`, onDelSsoDomain)

		return () => {
      offData('getSystemSettings'); 
      offData('setIdleTracking');
      offData('setIdleTrackingAuth');
      offData('setWarnExtension');
      offData('setWarnExtensionAuth')
      offData('setWebcamTracking');
      offData(`getSsoDomains`);
      offData(`insSsoDomain`);
      offData(`delSsoDomain`);
		}
    }, []);

    return isLoading ? (
        <Loader/>
    ) : ( 
      <React.Fragment>
        <style>
          {domainStyle}
        </style>
        <Modal backdrop={alertModalContent.static ? "static" : true} show={showAlertModal} onHide={() => setShowAlertModal(false)}>
          <Modal.Header>
          <Modal.Title>{alertModalContent.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{alertModalContent.text}</Modal.Body>
          <Modal.Footer>
          {alertModalContent.confirm ? 
            <Button variant="primary" onClick={() => alertModalContent.confirmHandler()}>
                {alertModalContent.confirmButton}
            </Button>
          : 
            null
          }
          {alertModalContent.cancel ? 
            <Button variant="danger" onClick={() => setShowAlertModal(false)}>
                {alertModalContent.cancelButton}
            </Button>
          : 
            null
          }
          </Modal.Footer>
        </Modal>

        <Modal backdrop={true} show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
          <Modal.Header>
          <Modal.Title>{confirmModalContent.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{confirmModalContent.text}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => setShowConfirmModal(false)}>
                Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Row> 
          <Col>
            { hasSaved ? 
              <Alert variant="success">
                <Alert.Heading>Success!</Alert.Heading>
                <p>Your changes have been automatically saved.</p>
              </Alert>
              :
              null
            }

            <CardDeck>
              <Card className="mb-2 justify-content-center">
                <Card.Header>
                  Idle Tracking
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Please Be Aware: Users will need to log out
                    and log back in for changes to take effect.
                  </Card.Text>
                  <Card.Title>
                    { systemSettings.trackIdle ? 
                        <Form>
                          <Row>
                            <Form.Group as={Col} xs={6} className="pb-1">
                              <Form.Switch
                                id="idle-switch"
                                checked={systemSettings.trackIdle ? true : false}
                                onChange={(e) => updateIdleTracking(e.target.checked)}
                              />
                            </Form.Group>
                            <Form.Group as={Col} xs={6} className="pb-1">
                                {/* <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 150, hide: 500}}
                                  overlay={renderTooltip({message: maxIdleTrackingMessage})}
                                >
                                  <FontAwesomeIcon 
                                    size="xs"
                                    pull="right"
                                    style={styles.icons.info}
                                    icon={faInfoCircle}
                                  />
                                </OverlayTrigger> */}
                              <Form.Control as="select" defaultValue={String(systemSettings.maxIdleTrackAuth)} onChange={(e) => updateIdleTrackingAuth(e.target.value)} size="sm">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </Form.Control>
                            </Form.Group>
                            <Col xs={6}>
                              <Card.Text>
                                This checkbox will enable idle tracking for all your Daily Stream users.
                                To manage specific users who will have idle tracking enabled, please use the menu
                                on the right.
                              </Card.Text>
                            </Col>
                            <Col xs={6}>
                              <Card.Text>
                                This menu will allow you to set the maximum authorization level that idle tracking
                                will be enabled for. Users with this authorization level AND lower will have idle tracking
                                enabled. To disable idle tracking for your users, please use the checkbox on the left.
                              </Card.Text>
                            </Col>
                          </Row>
                        </Form>
                      :
                        <Form>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Switch
                                id="idle-switch"
                                checked={systemSettings.trackIdle ? true : false}
                                onChange={(e) => updateIdleTracking(e.target.checked)}
                              />
                            </Form.Group>
                          </Row>
                        </Form>
                    }
                  </Card.Title>
                </Card.Body>
              </Card>
              <Card className="mb-2" >
                <Card.Header>
                  Recording Required
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Please Be Aware: Users will need to log out
                    and log back in for changes to take effect.
                  </Card.Text>
                  <Card.Title>
                    { systemSettings.warnExtension ? 
                        <Form>
                          <Row>
                            <Form.Group as={Col} xs={6} className="pb-1">
                              <Form.Switch
                                id="idle-switch"
                                checked={systemSettings.warnExtension ? true : false}
                                onChange={(e) => updateWarnExtension(e.target.checked)}
                              />
                            </Form.Group>
                            <Form.Group as={Col} xs={6} className="pb-1">
                                {/* <OverlayTrigger
                                  placement="right"
                                  delay={{ show: 150, hide: 500}}
                                  overlay={renderTooltip({message: maxWarnExtensionMessage})}
                                >
                                  <FontAwesomeIcon 
                                    size="xs"
                                    pull="right"
                                    style={styles.icons.info}
                                    icon={faInfoCircle}
                                  />
                                </OverlayTrigger> */}
                              <Form.Control as="select" defaultValue={String(systemSettings.maxWarnExtensionAuth)} onChange={(e) => updateWarnExtensionAuth(e.target.value)} size="sm">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                              </Form.Control>
                            </Form.Group>
                            <Col xs={6}>
                              <Card.Text>
                                This checkbox will enable a notification for all Daily Stream users
                                that they must record their stream. The notification will trigger once at 5 minutes
                                after logging in, once at 10 minutes, and finally once more at 15 minutes. 
                                To manage specific users who will have this notification enabled, please use the menu
                                on the right.
                              </Card.Text>
                            </Col>
                            <Col xs={6}>
                              <Card.Text>
                                This menu will allow you to set the maximum authorization level that the notification
                                will be enabled for. Users with this authorization level AND lower will have the notification
                                trigger if they have not begun recording. To disable the notification for your users, please use the checkbox on the left.
                              </Card.Text>
                            </Col>
                          </Row>
                        </Form>
                      :
                        <Form>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Label>Extension Warning ON/OFF</Form.Label>
                              <Form.Switch
                                id="idle-switch"
                                checked={systemSettings.warnExtension ? true : false}
                                onChange={(e) => updateWarnExtension(e.target.checked)}
                              />
                            </Form.Group>
                          </Row>
                        </Form>
                    }
                  </Card.Title>
                </Card.Body>
              </Card>  
              {/* <Card className="mb-2">
                <Card.Header>Webcam Settings</Card.Header>
                <Card.Body>
                  <Card.Title>
                    { systemSettings.trackWebcam ?
                      <Form>
                        <Row>
                          <Form.Group>
                            <Form.Label>Webcam ON/OFF</Form.Label>
                            <Form.Switch
                              id="webcam-switch"
                              checked={systemSettings.trackWebcam ? true : false}
                              onChange={(e) => updateTrackingWebcam(e.target.checked)}
                            />
                          </Form.Group>
                        </Row>
                      </Form>
                    :
                      <Form>
                        <Row>
                          <Form.Group>
                            <Form.Label>Webcam ON/OFF</Form.Label>
                            <Form.Switch
                              id="webcam-switch"
                              checked={systemSettings.trackWebcam ? true : false}
                              onChange={(e) => updateTrackingWebcam(e.target.checked)}
                            />
                          </Form.Group>
                        </Row>
                      </Form>
                    }
                  </Card.Title>
                </Card.Body>
              </Card> */}
            </CardDeck>
          </Col>
        </Row>
      </React.Fragment>
    );
}

export default SystemSettings;
