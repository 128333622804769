class FolderObject {
	constructor() {
		this.folderHistory = []
	}
	addHistoryItem = (id) => {
		this.folderHistory.push(id)
	}
	removeHistoryItem = (e) => {
		return this.folderHistory.pop()  
	}
}

export default new FolderObject();
