import React, { useEffect, useState } from 'react';
import Loader from '../Fragments/Loader';
import ClipControls from '../Components/ClipControls';
import Player from '../Components/Player';
import Viewer from '../Components/Viewer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import MultipleTrigger from '../Components/MultipleTrigger';
import { sendData, onData, offData, joinRoom, leaveRoom } from '../Hooks/Socket';
import './style.css';

const styles = {
	spinner: {
		height: '1.2rem',
		width: '1.2rem',
		marginLeft: '15px'
	},
	floatRightFlex: {
		marginLeft: 'auto'
	},
	alignMiddle: {
		alignItems: 'center'
	}
}

function Clip(props) {
	const [clipName, setClipName] = useState("");
  const [comments, setComments] = useState(null);
	const [isUnavailable, setIsUnavailable] = useState(true);
	const [errorMessage, setErrorMessage] = useState(<Loader />);

	const playClipOnLoad = (attempts, clipStartTime = 0) => {
		setTimeout(function() {

		if (Viewer.isReady && Viewer.canPlayNow) {

			Viewer.getClipData(clipStartTime);
		} else {

			attempts++;
			
			if(attempts <= 55) {
				playClipOnLoad(attempts, clipStartTime);
			}
		}
		}, 100);
	}

	/**
	 * Sets clip data on the Viewer
	 * @param {JSON} data response data 
	 */
	const onLoadClipPage = (data) => {
		if (data.verified) {
			//Set component states
			setIsUnavailable(false);
			setClipName(data.clip.name);

			//Set Viewer data
			Viewer.setCustomDay(data.clip.date);
			Viewer.clipStartTime = data.clip.startTime
			Viewer.isReady = true;
		} else {

			//Set error message
			setIsUnavailable(true);
			setErrorMessage(
				<Alert variant="danger">
				<Alert.Heading>No Clip Available!</Alert.Heading>
				<p>
				Oh no! It looks like the clip you requested is 
				unavailable or cannot be viewed at this time. 
				If you believe this is an error, please report 
				this information to your Systems Administrator.
				</p>
				</Alert>
			);
		}
	}

  const onGetComments = (comments) => {
    let component = [];

    for (let comment of comments) {
      let commentStartTime = comment.timeInFrame ? comment.timeInFrame : 0;

      component.push(
        <tr key={comment.id}>
          <td><button onClick={() => { Viewer.playClipFromPosition(commentStartTime) }}>Play</button></td>
          <td>{comment.author}</td>
          <td>{comment.comment}</td>
        </tr>
      );
    }

    setComments(<>{component}</>);
  }
  
	useEffect(() => {
    MultipleTrigger.tabsRole = 'Tab'; // Required field to use our custom component
    joinRoom(`group-${props.match.params.id}`);

    if (props?.match?.params?.commentId) {
      let data = {
        genUserId: localStorage.getItem('genUserId'), 
        commentId:  props.match.params.commentId, 
        userId: props.match.params.id, 
        channel: `individual-${props.match.params.commentId}`
      }
      sendData('mentionRead', data);
    }

		if (props?.match?.params?.UID) {
			Viewer.page = "clip";
			Viewer.clipId = props.match.params.id;
      Viewer.clipUID = props.match.params.UID;
			Viewer.isReady = false;
		}
		localStorage.setItem('viewedChannel', props.match.params.id);
		document.addEventListener('pointerdown', () => { Viewer.canplay() }, false);

		let data = {
			genUserId: localStorage.getItem('genUserId'),
			clipId: props.match.params.id,
		}
		sendData('loadClipPage', data);

    let getCommentsData = {
			channel: `group-${props.match.params.id}`,
			objectId: props.match.params.id, 
			genUserId: localStorage.getItem('genUserId'),
      isClip: true
		}
		sendData('getComments', getCommentsData);

    if (props.match.params?.time) {
      playClipOnLoad(0, parseInt(props.match.params.time));
    } else {
      playClipOnLoad(0);
    }

		onData('loadClipPage', onLoadClipPage);
    onData('getComments', onGetComments);

		return () => {
			offData('loadClipPage');
      offData('getComments');
      leaveRoom(`group-${props.match.params.id}`);
		}
	}, [props.match.params.id, props.match.params.time]);

	return isUnavailable ? (
		<React.Fragment>
		<Container>
			{errorMessage}
		</Container>
		</React.Fragment>
	) : (
		<React.Fragment>
		<Container id="playerContainerMain"  className="expanded-view">
			<Row style={{ marginTop: '15px' }}>
			<Card style={{ width: '100%' }}>
				<Card.Header>
				<Row style={styles.alignMiddle}>
					<span className="ml-2">{clipName}</span>
					<Badge variant="primary" className="ml-2">{Viewer.getCustomDay()}</Badge>
					<Spinner 
					id="playerSpinner" 
					style={styles.spinner} 
					animation="grow" 
					role="status" 
					variant="danger"
					>
					<span className="sr-only">Loading...</span>
					</Spinner>
					{/* <Button 
					variant="light" 
					style={styles.floatRightFlex}
					onClick={expandView}
					>
					<FontAwesomeIcon
						className="right"
						icon={arrows}
					/>
					</Button> */}
				</Row>
				</Card.Header>
				<Card.Body style={{ minHeight: '580px' }}>
				<Row id="playerContainer" >
					<Player />
				</Row>
				<Row id="controlPanel" className="hidden" >
					<ClipControls key={props.match.params.id} room={props.match.params.id}/>
				</Row>
				</Card.Body>
			</Card>
			</Row>
      <Row style={{ marginTop: '15px' }}>
			<Tabs>
				<TabList>
					<Tab>Comments</Tab>
				</TabList>
        <TabPanel>
          <MultipleTrigger data={Viewer.userList} />
          <table className="table table-striped">
            <thead>
            <tr>
              <th style={{width: '10%'}}>Jump To</th>
              <th style={{width: '10%'}}>Author</th>
              <th style={{width: '60%'}}>Comment</th>
              {/* <th style={{width: '10%'}}>Action</th> */}
            </tr>
            </thead>
            <tbody>
              {comments}
            </tbody>
            </table>
        </TabPanel>
			</Tabs>
			</Row>
		</Container>
		</React.Fragment>
	);
}

export default Clip;
