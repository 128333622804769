import Container from 'react-bootstrap/Container';
import fetch from '../Hooks/FetchLibrary';
import React, { 
  useState, 
  useEffect,
} from 'react';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);



const stateArray = [
  "Idle",
  "Active",
  "Start Broadcasting",
  "Stop Broadcasting",
  "Active Not Broadcasting"
]



  



function Report() {
  const [report, setReport] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [reportTitle, setReportTitle] = useState('Today ' + new Date().toISOString().split('T')[0]);
  const [csvObject, setCsvObject] = useState([]);
  const [chartContainerStyle, setChartContainerStyle] = useState({position: "relative", height:"40vh", width:"80vw"});





    useEffect(() => {
      
      if(startDate && endDate){
        setReportTitle(startDate + '_' + endDate);
        requestData();
      }

    }, [startDate, endDate]);

    const requestData = (e) => {
      // e.preventDefault();
      
      let newStartDate = new Date(startDate);
      let userTimezoneOffset = newStartDate.getTimezoneOffset() * 60000;
      newStartDate = new Date(newStartDate.getTime() + userTimezoneOffset);
      let newEndDate = new Date(endDate);
      userTimezoneOffset = newEndDate.getTimezoneOffset() * 60000;
      newEndDate = new Date(newEndDate.getTime() + userTimezoneOffset);
      if(checkPastDate(newStartDate) && checkPastDate(newEndDate) && newStartDate  <= newEndDate){
        fetch.fetchData('activityReport', 'POST', {startDate: startDate, endDate: endDate}).
        then((response) => {
          loadReport(response);
        })
      }else{
        alert('Please enter a valid date range');
      }

      
     
    }

    const loadReport = (report) => {
      let reportUsers = [];

      let activeData = [];
      let inactiveData = [];
      let csv = {};
      for(let i = 0; i < report.length; i++){
        if(reportUsers.indexOf(report[i].username) === -1){
          reportUsers.push(report[i].username);
        }
        inactiveData.push(parseInt(report[i].inactiveDuration/60));
        activeData.push(parseInt(report[i].activeDuration/60));
        if(!csv[report[i].username]){
          csv[report[i].username] = {};
        }
      
        
        csv[report[i].username]['Active'] = parseInt(report[i].activeDuration/60);
        csv[report[i].username]['Inactive'] = parseInt(report[i].inactiveDuration/60);
        setCsvObject(csv);


      }

      for(let i = 0; i < reportUsers.length; i++){
        
        reportUsers[i] = reportUsers[i] + ' - ' + toHoursAndMinutes(activeData[i]) + ' / ' + toHoursAndMinutes(inactiveData[i]);
      }
      let height = reportUsers.length * 90 + 'px';
      setChartContainerStyle({position: "relative", height:height, width:"80vw"});

      let reportOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: 'Activity Chart' + ' - ' + reportTitle,
          }
        },
        indexAxis: "y",
        scales: {
          x: {
            stacked: true,
            display: true
          },
          y: {
            stacked: true,
          },
        },
      
      }
      let reportData = {
        labels: reportUsers,
        datasets: [
          {
            label: 'Minutes Active',
            data: activeData,
            backgroundColor: "rgba(40,67, 135, 1)",
            hoverBackgroundColor: "rgba(37,41,88,1)",
            
          },
          {
            label: 'Minutes Inactive',
            data: inactiveData,
            backgroundColor: "rgba(175,65,84,1)",
            hoverBackgroundColor: "rgba(196,77,86,1)"
          }
        ]
      };

      
      setReport(<Bar id="barchartid" options={reportOptions} data={reportData} />)
     }

    const toHoursAndMinutes = (minutes) => {

      let hours = Math.floor(minutes/60);
      let minutesLeft = minutes % 60;
      return pad(hours) + ':' + pad(minutesLeft);
    }

    const pad = (num) => {
      
      return isNaN(num) ? "00" :  num.toString().padStart(2, '0');
    }

    const checkPastDate = (date) => {
      let today = new Date();
      return today >= date;
    }

    const downloadCSV = () => {
      let csv = 'Username,Minutes Active,Minutes Inactive\n';
      for(let user in csvObject){
        csv += user + ',' + csvObject[user].Active + ',' + csvObject[user].Inactive + '\n';
      }
      let hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download =`ActivityReport_${reportTitle.replace(/ /g,"_")}.csv`;
      hiddenElement.click();
    }

    const downloadPNG = () => {

      let canvas = document.querySelector('canvas');
      let hiddenElement = document.createElement('a');
      hiddenElement.href = canvas.toDataURL("image/png");
      hiddenElement.target = '_blank';
      hiddenElement.download =`ActivityReport_${reportTitle.replace(/ /g,"_")}.png`;
      hiddenElement.click();
    }

    const searchDate = (value) => {
      let today = new Date();
      let tempStart;
      let tempEnd = new Date();
      let title = '';
      if(value === 'YTD'){
        tempStart = new Date(`${today.getFullYear()}-01-01`);
        title = 'Year To Date '
        setReportTitle(title + tempStart.toISOString().split('T')[0] + '-' + tempEnd.toISOString().split('T')[0]);
      }else if(value === 'TD'){
        title = 'Today ';
        tempStart = today
        setReportTitle(title + tempStart.toISOString().split('T')[0]);
      }
      else if(value == 'YD'){
        title = 'Yesterday ';
        today.setDate(today.getDate() - 1)
        tempStart = today;
        tempEnd = tempStart
        setReportTitle(title + tempStart.toISOString().split('T')[0]);
      }else{
        title = `Last ${value} Days`;
        today.setDate(today.getDate() - value);
        tempStart = today
        setReportTitle(title + tempStart.toISOString().split('T')[0] + '-' + tempEnd.toISOString().split('T')[0]);
        
      }
      
      setStartDate(tempStart.toISOString().split('T')[0]);
      setEndDate(tempEnd.toISOString().split('T')[0]);
    }


  return (
    <React.Fragment>
      <Container>
      <Row className="mt-2">
        <Col>
        
        <InputGroup size="sm" className="mb-3">
                    <Form.Label>Start getDate
                      <FormControl type="date" id="s_date" value={startDate} onChange={e => setStartDate(e.target.value)} required/>
                    </Form.Label>
                    <Form.Label>End Date
                      <FormControl type="date" id="e_date" value={endDate} onChange={e => setEndDate(e.target.value)}  required/>
                    </Form.Label>
                    <Form.Label> Date Range
                      <FormControl as="select" id="standardDates" value="" onChange={e => searchDate(e.target.value)}>
                        <option value="">Select a Date Range</option>
                        <option value="TD">Today</option>
                        <option value="YD">Yesterday</option>
                        <option value="7">Last 7 Days</option>
                        <option value="14">Last 14 Days</option>
                        <option value="30">Last 30 days</option>
                        <option value="60">Last 60 Days</option>
                        <option value="90">Last 90 Days</option>
                        <option value="YTD">YTD</option>
                        </FormControl>
                       
                    </Form.Label>
                  
                 
                  </InputGroup>
        </Col>
        <Col>
        <Form.Label> 
            <br />
                    <button className="btn btn-primary" onClick={downloadCSV}><i className="fa-solid fa-file-arrow-down"></i> Download Data CSV</button>  <button className="btn btn-primary" onClick={downloadPNG}><i className="fa-regular fa-image"></i> Download Chart Image</button>
                    </Form.Label>
        </Col>
      </Row>
      <hr />
      <Row className="mt-2">
        <Col>
          <div id="chart-container" style={chartContainerStyle}>
           {report}
           </div>
        </Col>
      </Row>
      </Container>
    </React.Fragment>
  );
}

export default Report;
